import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  useDeleteApAdvanceMutation,
  useDeleteApCreditNoteMutation,
  useDeleteApCreditorCreditNoteMutation,
  useDeleteApCreditorDebitNoteMutation,
  useDeleteApDebitNoteMutation,
  useDeleteApInvoiceMutation,
  useDeleteApPaymentMutation,
  useDeleteApRefundMutation,
  useDeleteApSelfBilledMutation,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext } from 'react'

interface APDeleteMutation {
  apSubmenu: string
  loadData: any
  CompanyID: string
  setOriginalListing: any
  setOpenDeleteDialog: any
  setErrorDia?
  setErrMsg?
}

export const useAPDeleteMutation = ({
  apSubmenu,
  loadData,
  CompanyID,
  setOriginalListing,
  setOpenDeleteDialog,
  setErrorDia,
  setErrMsg,
}: APDeleteMutation) => {
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  /* -------------------------------------------- */
  /*                POST MUTATION               */
  /* -------------------------------------------- */

  const [
    deleteAPAdvance,
    {
      loading: deleteAPAdvanceLoading,
      error: deleteAPAdvanceError,
      called: deleteAdvanceCalled,
    },
  ] = useDeleteApAdvanceMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOriginalListing([])
      setOpenDeleteDialog(false)
      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'ACTIVE',
          skip: 0,
          take: 30,
          searchValue:
            getSearch?.name === '' || !getSearch?.name
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })
  const [
    deleteAPInvoice,
    {
      loading: deleteAPInvoiceLoading,
      error: deleteAPInvoiceError,
      called: deleteInvoiceCalled,
    },
  ] = useDeleteApInvoiceMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOriginalListing([])
      setOpenDeleteDialog(false)
      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'ACTIVE',
          skip: 0,
          take: 30,
          searchValue:
            getSearch?.name === '' || !getSearch?.name
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    deleteAPCreditorDebitNote,
    {
      loading: deleteAPCreditorDebitNoteLoading,
      error: deleteAPCreditorDebitNoteError,
      called: deleteCreditorDebitNoteCalled,
    },
  ] = useDeleteApCreditorDebitNoteMutation({
    onError: error => {
      // if (error.message.includes('FK_')) {
      //   setErrorDia(true)
      // }
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOriginalListing([])
      setOpenDeleteDialog(false)
      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'ACTIVE',
          skip: 0,
          take: 30,
          searchValue:
            getSearch?.name === '' || !getSearch?.name
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })
  const [
    deleteAPDebitNote,
    {
      loading: deleteAPDebitNoteLoading,
      error: deleteAPDebitNoteError,
      called: deleteDebitNoteCalled,
    },
  ] = useDeleteApDebitNoteMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOriginalListing([])
      setOpenDeleteDialog(false)
      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'ACTIVE',
          skip: 0,
          take: 30,
          searchValue:
            getSearch?.name === '' || !getSearch?.name
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    deleteAPCreditNote,
    {
      loading: deleteAPCreditNoteLoading,
      error: deleteAPCreditNoteError,
      called: deleteCreditNoteCalled,
    },
  ] = useDeleteApCreditNoteMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOriginalListing([])
      setOpenDeleteDialog(false)
      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'ACTIVE',
          skip: 0,
          take: 30,
          searchValue:
            getSearch?.name === '' || !getSearch?.name
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })
  const [
    deleteAPCreditorCreditNote,
    {
      loading: deleteAPCreditorCreditNoteLoading,
      error: deleteAPCreditorCreditNoteError,
      called: deleteCreditorCreditNoteCalled,
    },
  ] = useDeleteApCreditorCreditNoteMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOriginalListing([])
      setOpenDeleteDialog(false)
      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'ACTIVE',
          skip: 0,
          take: 30,
          searchValue:
            getSearch?.name === '' || !getSearch?.name
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })
  const [
    deleteAPRefund,
    {
      loading: deleteAPRefundLoading,
      error: deleteAPRefundError,
      called: deleteRefundCalled,
    },
  ] = useDeleteApRefundMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOriginalListing([])
      setOpenDeleteDialog(false)
      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'ACTIVE',
          skip: 0,
          take: 30,
          searchValue:
            getSearch?.name === '' || !getSearch?.name
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    deleteAPPayment,
    {
      loading: deleteAPPaymentLoading,
      error: deleteAPPaymentError,
      called: deletePaymentCalled,
    },
  ] = useDeleteApPaymentMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOriginalListing([])
      setOpenDeleteDialog(false)
      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'ACTIVE',
          skip: 0,
          take: 30,
          searchValue:
            getSearch?.name === '' || !getSearch?.name
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })
  const [
    deleteAPSelfBilled,
    {
      loading: deleteAPSelfBilledLoading,
      error: deleteAPSelfBilledError,
      called: deleteSelfBilledCalled,
    },
  ] = useDeleteApSelfBilledMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOriginalListing([])
      setOpenDeleteDialog(false)
      loadData({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'ACTIVE',
          skip: 0,
          take: 30,
          searchValue:
            getSearch?.name === '' || !getSearch?.name
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const handleDelete =
    apSubmenu === 'advance'
      ? deleteAPAdvance
      : apSubmenu === 'invoice'
      ? deleteAPInvoice
      : apSubmenu === 'debit-note'
      ? deleteAPDebitNote
      : apSubmenu === 'creditor-debit-note'
      ? deleteAPCreditorDebitNote
      : apSubmenu === 'refund'
      ? deleteAPRefund
      : apSubmenu === 'payment'
      ? deleteAPPayment
      : apSubmenu === 'credit-note'
      ? deleteAPCreditNote
      : apSubmenu === 'creditor-credit-note'
      ? deleteAPCreditorCreditNote
      : apSubmenu === 'self-billed'
      ? deleteAPSelfBilled
      : ''

  const mutationLoading =
    apSubmenu === 'advance'
      ? deleteAPAdvanceLoading
      : apSubmenu === 'invoice'
      ? deleteAPInvoiceLoading
      : apSubmenu === 'debit-note'
      ? deleteAPDebitNoteLoading
      : apSubmenu === 'creditor-debit-note'
      ? deleteAPCreditorDebitNoteLoading
      : apSubmenu === 'refund'
      ? deleteAPRefundLoading
      : apSubmenu === 'payment'
      ? deleteAPPaymentLoading
      : apSubmenu === 'credit-note'
      ? deleteAPCreditNoteLoading
      : apSubmenu === 'creditor-credit-note'
      ? deleteAPCreditorCreditNoteLoading
      : apSubmenu === 'self-billed'
      ? deleteAPSelfBilledLoading
      : ''

  const deleteCalled =
    apSubmenu === 'advance'
      ? deleteAdvanceCalled
      : apSubmenu === 'invoice'
      ? deleteInvoiceCalled
      : apSubmenu === 'debit-note'
      ? deleteDebitNoteCalled
      : apSubmenu === 'creditor-debit-note'
      ? deleteCreditorDebitNoteCalled
      : apSubmenu === 'refund'
      ? deleteRefundCalled
      : apSubmenu === 'payment'
      ? deletePaymentCalled
      : apSubmenu === 'credit-note'
      ? deleteCreditNoteCalled
      : apSubmenu === 'creditor-credit-note'
      ? deleteCreditorCreditNoteCalled
      : apSubmenu === 'self-billed'
      ? deleteSelfBilledCalled
      : ''

  return {
    handleDelete,
    mutationLoading,
    deleteCalled,
  }
}
