import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Grid, List, ListItem, ListItemText } from '@material-ui/core'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { useGetRoutineJournalQuery } from 'generated/graphql'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const RoutineJournalDetail = (props: any) => {
  let history = useHistory()
  const { CompanyID, RoutineJournalID }: any = useParams()
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const {
    loading: RoutineJournalLoading,
    error: RoutineJournalError,
    data: { getRoutineJournal } = { getRoutineJournal: [] },
  } = useGetRoutineJournalQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    variables: { RoutineJournalID: RoutineJournalID },
  })

  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  return (
    <>
      {RoutineJournalLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/general-ledger/${CompanyID}/routine-journal`)
        }
        smTitle="Company Setting"
        title={user?.companyName}
        currency={`MYR`}
        routeSegments={[
          { name: 'General Ledger' },
          { name: 'Routine Journal' },
          { name: 'Detail', current: true },
        ]}
      />
      <ContentWrapper>
        <CardContents
          section={{
            header: {
              title: 'Routine Journal Details',
            },
          }}
        >
          <div className="content-wrap full">
            <div className="desc">Journal Type</div>
            <div className="xsTitle">
              {' '}
              {getRoutineJournal?.[0]?.JournalType?.JournalType}{' '}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc">Description</div>
            {/* <div className="xsTitle">{formatDate(dummyData[0]?.docDate)}</div> */}
            <div className="xsTitle">
              {' '}
              {getRoutineJournal?.[0]?.Description}{' '}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc">RefNo</div>
            {/* <div className="xsTitle">{formatDate(dummyData[0]?.docDate)}</div> */}
            <div className="xsTitle"> {getRoutineJournal?.[0]?.RefNo} </div>
          </div>

          <div className="content-wrap full">
            <div className="desc">Remark</div>
            {/* <div className="xsTitle">{formatDate(dummyData[0]?.docDate)}</div> */}
            <div className="xsTitle"> {getRoutineJournal?.[0]?.Remark} </div>
          </div>
        </CardContents>

        <CardContents
          section={{
            header: {
              title: 'Routine Journal Item',
            },
          }}
        >
          <div className="rm-padding table-wrap">
            <List className="core-list content-wrap full " disablePadding>
              <ListItem className="table-header p-b-10">
                <ListItemText
                  primary={
                    <Grid container justify="flex-start" spacing={1}>
                      <Grid item xs={1}>
                        <span className="fw-medium desc text-right">No</span>
                      </Grid>
                      <Grid item xs={6} className="left-text">
                        <span className="fw-medium desc text-right">
                          Account Code
                        </span>
                      </Grid>
                      <Grid item xs={5} className="left-text">
                        <span className="fw-medium desc text-right">
                          Journal Amount
                        </span>
                      </Grid>
                    </Grid>
                  }
                />
              </ListItem>

              {getRoutineJournal?.[0]?.RoutineJournalItem?.map((el, index) => (
                <ListItem className="table-listItem " key={index} divider>
                  <ListItemText
                    primary={
                      <Grid container justify="flex-start" spacing={1}>
                        <Grid
                          item
                          xs={1}
                          className="fw-medium desc text-right"
                          //component="span"
                        >
                          {index + 1}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          className="fw-medium desc text-right"
                          //component="span"
                        >
                          {el.MasterCOA.Name}
                        </Grid>

                        <Grid
                          item
                          xs={5}
                          className="fw-medium desc text-right"
                          //component="span"
                        >
                          {amtStr(el.DocAmt)}
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
          <div
            className="footer-label"
            style={{
              background: '#bfd3ff',
            }}
          ></div>
        </CardContents>
      </ContentWrapper>
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
