import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import {
  Divider,
  IconButton,
  InputBase,
  List,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

export const SwitchDialog = props => {
  const {
    data,
    open,
    onClose,
    currID,
    setCurrID,
    name,
    nameID,
    path,
    customPath,
  } = props
  let history = useHistory()
  const [switchID, setSwitchID] = useState(currID)
  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    reset,
  } = useFuseSearch()

  useEffect(() => {
    if (data && data?.length > 0) {
      setOriginalListing(data)
    }
  }, [data])

  return (
    <StandardDialog
      fullWidth={true}
      open={open}
      onClose={onClose}
      sections={{
        header: {
          // title: `Switch ${name}`,
          smallTitle: `Switch ${name}`,
        },
        body: () => (
          <>
            <InputBase
              // className="m-b-20"
              fullWidth
              onChange={e => {
                handleSearch(e?.target?.value, ['Name'])
              }}
              placeholder="Search here.."
              inputProps={{ 'aria-label': 'search' }}
            />

            {filteredList
              ?.sort((a, b) => {
                return a.Name.localeCompare(b.Name)
              })
              ?.map((item, index) => {
                return (
                  <div key={item[nameID]}>
                    <List
                      className={
                        item[nameID] === switchID
                          ? 'bg-blue-light core-list'
                          : ''
                      }
                      key={item[nameID]}
                      onClick={() => {
                        setSwitchID(item[nameID])
                        setCurrID(item[nameID])
                        if (name === 'Warehouse') {
                          localStorage.setItem('latestWarehouse', item[nameID])
                        } else {
                          localStorage.setItem('latestCompany', item[nameID])
                        }
                        history.push(
                          path
                            ? `${path}/${item[nameID]}`
                            : customPath
                            ? customPath?.replace('currID', item[nameID])
                            : ''
                        )
                        onClose()
                      }}
                    >
                      {/* <Divider
                        variant="fullWidth"
                        // style={{ background: '#f4f4f4' }}
                      ></Divider> */}
                      {/* <ListItem
                      className={
                        item[nameID] === switchID ? 'bg-blue-light' : ''
                      }
                      key={item[nameID]}
                      onClick={() => {
                        setSwitchID(item[nameID])
                        setCurrID(item[nameID])
                        localStorage.setItem('latestCompany', item[nameID])
                        history.push(`${path}/${item[nameID]}`)
                        onClose()
                      }}
                    > */}
                      <ListItemText
                        primary={
                          <>
                            <span
                              className="xsTitle"
                              style={{ marginRight: '4px' }}
                            >
                              {item?.Name}
                            </span>
                            {name === 'Warehouse' ? null : (
                              <span className="desc">
                                {'(' + item?.CompanyRegNo + ')'}
                              </span>
                            )}
                          </>
                        }
                        secondary={
                          name === 'Warehouse' ? (
                            <span className="desc">{item?.Address}</span>
                          ) : (
                            <span className="desc">
                              {item?.Address?.address},{' '}
                              {item?.Address?.postcode} {item?.Address?.city},{' '}
                              {item?.Address?.state}, {item?.Address?.country}
                            </span>
                          )
                        }
                      />
                      <Divider
                        variant="fullWidth"
                        // style={{ background: '#f4f4f4' }}
                      ></Divider>
                      {item[nameID] === switchID && (
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete">
                            <Check color="primary" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                      {/* </ListItem> */}
                    </List>
                  </div>
                )
              })}
          </>
        ),
        footer: {
          actions: [
            // {
            //   displayText: 'Close',
            //   props: {
            //     onClick: () => {
            //       onClose()
            //       reset()
            //     },
            //     variant: 'contained',
            //     color: 'primary',
            //   },
            // },
            // {
            //   displayText: 'Confirm',
            //   props: {
            //     onClick: () => {
            //       setCurrID(switchID)
            //       localStorage.setItem('latestCompany', switchID)
            //       history.push(`${path}/${switchID}`)
            //       onClose()
            //     },
            //     variant: 'contained',
            //     color: 'primary',
            //   },
            // },
          ],
        },
      }}
    />
  )
}
