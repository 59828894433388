import theme from '@ifca-root/react-component/src/assets/theme'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ExcelInput } from '@ifca-root/react-component/src/components/Input/ExcelUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { useExcelUploadVersion2 } from '@ifca-root/react-component/src/utils/hooks/excelUploadVersion2'
import { List, ListItem, ListItemText, useMediaQuery } from '@material-ui/core'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  useCreateCreditorProfileImportMutation,
  useGetCheckingValidationCreditorProfileQuery,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export const CreditorProfileExcelValidation = (props: any) => {
  let location = useLocation()
  const passedData: any = location?.state
  const fileName = passedData.fileTitle
  let history = useHistory()
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [validatedData, setValidatedData] = useState<any>({
    ErrorData: [],
    ValidData: [],
  })
  const [openDialog, setOpenDialog] = useState(false)

  const {
    loading: CheckingValidationCreditorProfileLoading,
    error: getCheckingValidationCreditorProfileError,
    data: { getCheckingValidationCreditorProfile } = {
      getCheckingValidationCreditorProfile: [],
    },
  } = useGetCheckingValidationCreditorProfileQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: {
      CreditorProfileImportInput: passedData.excelData,
    },
    onCompleted: ({ getCheckingValidationCreditorProfile }) => {
      let ErrorData: any = []
      let ValidData: any = []

      getCheckingValidationCreditorProfile?.map(x => {
        if (x['ErrorList']?.length > 0)
          ErrorData.push({
            CreditorCategory: x['CreditorCategory'],
            CompanyName: x['CompanyName'],
            CompanyRegNo: x['CompanyRegNo'],
            TaxIdentificationNo: x['TaxIdentificationNo'],
            CreditorType: x['CreditorType'],
            ContactNo: x['ContactNo'],
            Email: x['Email'],
            CreditTerm: x['CreditTerm'],
            Tax: x['Tax'],
            Region: x['Region'],
            GSTRegNo: x['GSTRegNo'],
            Remark: x['Remark'],
            BankAccountNo: x['BankAccountNo'],
            BankName: x['BankName'],
            SWIFTCode: x['SWIFTCode'],
            PayeeName: x['PayeeName'],
            Address: x['Address'],
            Country: x['Country'],
            State: x['State'],
            City: x['City'],
            PostCode: x['PostCode'],
            RowNo: x['RowNo'],
            ErrorList: x['ErrorList'],
          })
        else
          ValidData.push({
            CreditorCategory: x['CreditorCategory'],
            CompanyName: x['CompanyName'],
            CompanyRegNo: x['CompanyRegNo'],
            TaxIdentificationNo: x['TaxIdentificationNo'],
            CreditorType: x['CreditorType'],
            ContactNo: x['ContactNo'],
            Email: x['Email'],
            CreditTerm: x['CreditTerm'],
            Tax: x['Tax'],
            Region: x['Region'],
            GSTRegNo: x['GSTRegNo'],
            Remark: x['Remark'],
            BankAccountNo: x['BankAccountNo'],
            BankName: x['BankName'],
            SWIFTCode: x['SWIFTCode'],
            PayeeName: x['PayeeName'],
            Address: x['Address'],
            Country: x['Country'],
            State: x['State'],
            City: x['City'],
            PostCode: x['PostCode'],
            RowNo: x['RowNo'],
            ErrorList: x['ErrorList'],
          })
      })

      setValidatedData({ ErrorData: ErrorData, ValidData: ValidData })
    },
  })

  const [
    createCreditorProfileImport,
    { loading: createCreditorLoading, error: createCreditorProfileImportError },
  ] = useCreateCreditorProfileImportMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      history.push({
        pathname: `/account-payable/general-setting/creditor-profile/supplier`,
        state: {
          success: true,
          msgMode: 'create',
        },
      })
    },
  })

  const {
    openFileUpload,
    setOpenFileUpload,
    fileTitle,
    setFileTitle,
    openSnackBar,
    //setOpenSnackBar,
    snackBarMessage,
    setSnackBarMessage,
    currentData,
    setCurrentData,
    onDownloadTemplate,
    onSelectFile,
    duplicateItems,
    errorDialog: duplicateErrorDia,
    setErrorDialog,
    resetFileUpload,
  } = useExcelUploadVersion2()

  const createCreditorProfileExcel = data => {
    let stringifyData = JSON.stringify(data)

    createCreditorProfileImport({
      variables: { TemplateStr: stringifyData },
    })
  }

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <>
      {CheckingValidationCreditorProfileLoading && <Loading />}
      {createCreditorLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() =>
          history.push(
            `/account-payable/general-setting/creditor-profile/supplier`
          )
        }
        smTitle={'Creditor Profile'}
        title={user?.accountName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Creditor Profile', current: true },
        ]}
      />

      <DynamicSubHeader
        style={{
          marginTop: '-2px',
        }}
        title={'Creditor Profile Excel Upload'}
        rightText={<span className="c-orange">Validation</span>}
      />
      <List className="search-header fixed-search-header with-dynamic">
        <ListItem>
          <ListItemText
            primary={
              <>
                <span className="xsTitle flex-space">{fileName}</span>
              </>
            }
          />
        </ListItem>
      </List>

      <ContentWrapper
        style={{ marginTop: isDesktop ? '140px' : '75px' }}
        float
        footer
      >
        {/* Problem List */}
        {validatedData.ErrorData?.length > 0 && (
          <>
            <List style={{ marginBottom: '-13px' }}>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle" style={{ marginLeft: '-9px' }}>
                        Problem{' '}
                        {
                          <>
                            (
                            <span className="highlight-text">
                              {validatedData.ErrorData?.length}
                            </span>
                            )
                          </>
                        }
                      </span>
                    </>
                  }
                />
              </ListItem>
            </List>
            <List className="core-list">
              {validatedData.ErrorData?.map((el, index) => (
                <ListItem key={index}>
                  <ListItemText
                    // style={{
                    //   textAlign: 'right',
                    //   marginLeft: '5px',
                    // }}
                    primary={
                      <>
                        <div style={{ width: '100%' }}>
                          <div>
                            <span className="xsTitle flex-space ">
                              {el.CompanyName ?? '[No Company Name]'}{' '}
                            </span>
                          </div>

                          {el?.CreditorCategory && (
                            <div>
                              <span className="desc">
                                {el.CreditorCategory} - {el.CreditorType}
                              </span>
                            </div>
                          )}

                          <div>
                            <span className="desc">{el.CompanyRegNo}</span>
                          </div>

                          {el?.TaxIdentificationNo && (
                            <div>
                              <span className="desc">
                                {el.TaxIdentificationNo}
                              </span>
                            </div>
                          )}

                          <div>
                            <span className="desc">{el.ContactNo ?? ''}</span>
                          </div>

                          {el?.Email && (
                            <div>
                              <span className="desc">{el.Email ?? ''}</span>
                            </div>
                          )}

                          {el?.CreditTerm && (
                            <div>
                              <span className="desc">
                                Credit Terms: {el.CreditTerm ?? ''}
                              </span>
                            </div>
                          )}

                          {el?.Tax && (
                            <div>
                              <span className="desc">Tax: {el.Tax ?? ''}</span>
                            </div>
                          )}

                          {el?.BankName && (
                            <div>
                              <span className="desc">
                                Bank Name: {el.BankName ?? ''}
                              </span>
                            </div>
                          )}

                          {el?.BankAccountNo && (
                            <div>
                              <span className="desc">
                                Bank Acc No: {el.BankAccountNo ?? ''}
                              </span>
                            </div>
                          )}

                          {el?.SWIFTCode && (
                            <div>
                              <span className="desc">
                                SWIFT Code: {el.SWIFTCode ?? ''}
                              </span>
                            </div>
                          )}

                          {el?.PayeeName && (
                            <div>
                              <span className="desc">
                                Payee Name: {el.PayeeName ?? ''}
                              </span>
                            </div>
                          )}

                          <div>
                            <span className="desc">
                              {el.Address}, {el.PostCode}, {el.City}, {el.State}
                              , {el.Country}
                            </span>
                          </div>
                        </div>
                      </>
                    }
                    secondary={
                      <>
                        <div>
                          <div
                            className="xsTitle text-noflow"
                            style={{ color: 'red' }}
                          >
                            {' '}
                            Row No: {el.RowNo}
                          </div>
                          <div
                            className="xsTitle text-noflow"
                            style={{ color: 'red' }}
                          >
                            {' '}
                            {el.ErrorList?.map((x, index) => {
                              if (index == el.ErrorList?.length - 1)
                                return `${x}`
                              else return `${x}, `
                            })}
                          </div>
                        </div>
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}

        {/* Validation List */}
        {validatedData.ValidData?.length > 0 && (
          <>
            <List style={{ marginBottom: '-13px' }}>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle" style={{ marginLeft: '-9px' }}>
                        Validated{' '}
                        {
                          <>
                            (
                            <span className="highlight-text">
                              {validatedData.ValidData?.length}
                            </span>
                            )
                          </>
                        }
                      </span>
                    </>
                  }
                />
              </ListItem>
            </List>
            <List className="core-list">
              {validatedData.ValidData?.map((el, index) => (
                <ListItem key={index}>
                  <ListItemText
                    // style={{
                    //   textAlign: 'right',
                    //   marginLeft: '5px',
                    // }}
                    primary={
                      <>
                        <div style={{ width: '100%' }}>
                          <div>
                            <span className="xsTitle flex-space ">
                              {el.CompanyName ?? '[No Company Name]'}{' '}
                            </span>
                          </div>

                          {el?.CreditorCategory && (
                            <div>
                              <span className="desc">
                                {el.CreditorCategory} - {el.CreditorType}
                              </span>
                            </div>
                          )}

                          <div>
                            <span className="desc">{el.CompanyRegNo}</span>
                          </div>

                          {el?.TaxIdentificationNo && (
                            <div>
                              <span className="desc">
                                {el.TaxIdentificationNo}
                              </span>
                            </div>
                          )}

                          <div>
                            <span className="desc">{el.ContactNo ?? ''}</span>
                          </div>

                          {el?.Email && (
                            <div>
                              <span className="desc">{el.Email ?? ''}</span>
                            </div>
                          )}

                          {el?.CreditTerm && (
                            <div>
                              <span className="desc">
                                Credit Terms: {el.CreditTerm ?? ''}
                              </span>
                            </div>
                          )}

                          {el?.Tax && (
                            <div>
                              <span className="desc">Tax: {el.Tax ?? ''}</span>
                            </div>
                          )}

                          {el?.BankName && (
                            <div>
                              <span className="desc">
                                Bank Name: {el.BankName ?? ''}
                              </span>
                            </div>
                          )}

                          {el?.BankAccountNo && (
                            <div>
                              <span className="desc">
                                Bank Acc No: {el.BankAccountNo ?? ''}
                              </span>
                            </div>
                          )}

                          {el?.SWIFTCode && (
                            <div>
                              <span className="desc">
                                SWIFT Code: {el.SWIFTCode ?? ''}
                              </span>
                            </div>
                          )}

                          {el?.PayeeName && (
                            <div>
                              <span className="desc">
                                Payee Name: {el.PayeeName ?? ''}
                              </span>
                            </div>
                          )}
                          <div>
                            <span className="desc">
                              {el.Address}, {el.PostCode}, {el.City}, {el.State}
                              , {el.Country}
                            </span>
                          </div>
                        </div>
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </ContentWrapper>

      <ExcelInput
        showFooter={false}
        showDupErrors={true}
        duplicateItems={duplicateItems}
        errorDia={duplicateErrorDia}
        setErrorDia={setErrorDialog}
        openUpload={openFileUpload}
        setOpenUpload={setOpenFileUpload}
        upload={fileTitle}
        template={currentData}
        setTemplate={setCurrentData}
        setUpload={setFileTitle}
        resetFileUpload={resetFileUpload}
        onUploadTemplate={() => {
          history.push({
            pathname: `/account-payable/general-setting/creditor-profile/import/excel-validation`,
            state: {
              excelData: currentData?.map(x => {
                return {
                  CreditorCategory:
                    x?.CreditorCategory === null
                      ? null
                      : `${x?.CreditorCategory}`,
                  CompanyRegNo:
                    x?.CompanyRegNo === null ? null : `${x?.CompanyRegNo}`,
                  TaxIdentificationNo:
                    x?.TaxIdentificationNo === null
                      ? null
                      : `${x?.TaxIdentificationNo}`,
                  CompanyName:
                    x?.CompanyName === null ? null : `${x?.CompanyName}`,
                  CreditorType:
                    x?.CreditorType === null ? null : `${x?.CreditorType}`,
                  ContactNo: x?.ContactNo === null ? null : `${x?.ContactNo}`,
                  Email: x?.Email === null ? null : x?.Email.text,
                  CreditTerm:
                    x?.CreditTerm === null ? null : `${x?.CreditTerm}`,
                  Tax: x?.Tax === null ? null : `${x?.Tax}`,
                  Region: x?.Region === null ? null : `${x?.Region}`,
                  GSTRegNo: x?.GSTRegNo === null ? null : `${x?.GSTRegNo}`,
                  Remark: x?.Remark === null ? null : `${x?.Remark}`,
                  BankAccountNo:
                    x?.BankAccountNo === null ? null : `${x?.BankAccountNo}`,
                  BankName: x?.BankName === null ? null : `${x?.BankName}`,
                  SWIFTCode: x?.SWIFTCode === null ? null : `${x?.SWIFTCode}`,
                  PayeeName: x?.PayeeName === null ? null : `${x?.PayeeName}`,
                  Address: x?.Address === null ? null : `${x?.Address}`,
                  Country: x?.Country === null ? null : `${x?.Country}`,
                  State: x?.State === null ? null : `${x?.State}`,
                  City: x?.City === null ? null : `${x?.City}`,
                  PostCode: x?.PostCode === null ? null : `${x?.PostCode}`,
                  RowNo: x?.rowNo,
                }
              }),
              fileTitle: fileTitle,
            },
          })
          setOpenFileUpload(false)
          resetFileUpload()
          setFileTitle('')
        }}
        onSelectFile={(e: any) => {
          onSelectFile({ event: e })
        }}
        downloadButton={false}
        onDownloadTemplate={() => {}}
        snackBarProps={{
          openSnackBar: openSnackBar,
          snackBarMessage: snackBarMessage,
          setOpenSnackBar: setOpenSnackBar,
          onClickButton: () => {
            setOpenSnackBar(false)
          },
        }}
      />
      <Footer
        options={
          validatedData.ErrorData?.length === 0 &&
          validatedData.ValidData?.length > 0
            ? [
                {
                  onClick: () => {
                    setOpenDialog(true)
                  },
                  name: 'Confirm Upload',
                  color: 'primary',
                },
              ]
            : [
                {
                  name: 'Re-upload',
                  color: 'primary',
                  onClick: () => {
                    setOpenFileUpload(true)
                  },
                },
              ]
        }
      />

      <CommonDialog
        fullWidth={true}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
        }}
        sections={{
          header: {
            dynamic: (
              <div className="">
                <div className="dialog-dynamic-content">
                  <span
                    className="title c-orange flex-space"
                    style={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    Upload Confirmation
                  </span>
                </div>
              </div>
            ),
          },
          body: () => (
            <div>
              <span>{'Are you sure to upload?'}</span>
            </div>
          ),

          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setOpenDialog(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    createCreditorProfileExcel(
                      getCheckingValidationCreditorProfile
                    )
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
