import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { AREntryList } from './ARListing/AREntryList'
import { ARPostingList } from './ARListing/ARPostingList'
import { useARListingData } from './Hooks/useARListingData'
import { Tune } from '@material-ui/icons'
import { useFilterDialog } from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV2'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'

export const ARMain = (props: any) => {
  let history = useHistory()
  const { arSubmenu, routeSegments, accountType } = props

  const { CompanyID }: any = useParams()
  const approvalStatusEnum = [
    { name: 'Draft', ID: 'ACTIVE' },
    { name: 'Submitted', ID: 'SUBMIT' },
    { name: 'Rejected', ID: 'REJECTED' },
    { name: 'Approved', ID: 'COMPLETED' },
  ]
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const [postIDs, setPostIDs] = useState<Set<any>>(new Set([]))

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  // useEffect(() => {
  //   if (getSearch?.name && !!originalList) {
  //     const keys = [
  //       'DebtorAccount.Name',
  //       'DocAmt',
  //       'DocNo',
  //       'Description',
  //       'DocDateFormatted',
  //     ]
  //     const options = {
  //       shouldSort: true,
  //       threshold: 0.6,
  //       ignoreLocation: true,
  //       keys: keys,
  //     }

  //     const myFuse = new Fuse(originalList, options)
  //     const result = myFuse.search(getSearch?.name)
  //     const val = result?.map(x => x.item)
  //     // if (val.length !== 0) {
  //     handleSearch(getSearch?.name + '', keys)
  //     // }
  //   }
  // }, [getSearch?.name, originalList])

  const [listStatus, setListStatus] = useState<string>(
    getSearch?.option ?? 'ACTIVE'
  )

  const {
    loading,
    primaryKey,
    createPermissionCheck,
    updatePermissionCheck,
    deletePermissionCheck,
    approveRejectPermissionCheck,
    userList,
    loadData,
  } = useARListingData({
    accountType,
    arSubmenu,
    CompanyID,
    setOriginalListing,
    filteredList,
    setErrMsg,
    setErrorDia,
  })

  const { openFilterDialog, handleFilterDate, filterDialog } = useFilterDialog({
    dateRange: {
      title: 'Doc Date',
      key: 'DocDate',
    },
  })

  useEffect(() => {
    setOriginalListing([])
    loadData({
      variables: {
        CompanyID: CompanyID,
        StatusArr: [listStatus],
        take: 30,
        skip: 0,
        searchValue:
          getSearch?.name !== '' || getSearch?.name === undefined
            ? undefined
            : getSearch?.name,
      },
    })
  }, [listStatus])

  //to make delay for search function for the query to load
  const [timer, setTimer] = useState(null)
  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }

    setTimer(
      setTimeout(() => {
        setOriginalListing([])
        loadData({
          variables: {
            CompanyID: CompanyID,
            StatusArr: [listStatus],
            take: 30,
            skip: 0,
            searchValue: change,
          },
        })
      }, 1000)
    )
  }

  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  return (
    <>
      {loading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/account-receivable/${CompanyID}`)
          localStorage.removeItem(`searchFilter`)
        }}
        smTitle={'Accounts Receivable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Accounts Receivable' },
          { name: routeSegments, current: true },
        ]}
      />

      <div className="search-filter with-dropdown-filter">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            // handleSearch(e?.target?.value, [
            //   'DocNo',
            //   'Description',
            //   'RefNo',
            //   'DebtorAccount.Name',
            // ])
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: listStatus ?? 'ACTIVE',
                name: e?.target?.value,
              })
            )
            changeDelay(e?.target?.value)
          }}
          isDefaultValue={!!getSearch}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
            setOriginalListing([])
            localStorage.removeItem('searchFilter')
            loadData({
              variables: {
                CompanyID: CompanyID,
                StatusArr: [listStatus],
                skip: 0,
                take: 30,
              },
            })
          }}
          option={{
            icon: <Tune />,
            onClick: openFilterDialog,
          }}
        />
        {filterDialog}

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={setListStatus}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(listStatus ?? 'ACTIVE')}
          searchFilter={getSearch}
        />
      </div>

      {(listStatus === 'ACTIVE' ||
        listStatus === 'COMPLETED' ||
        listStatus === 'REJECTED') && (
        // !loading &&
        <AREntryList
          arSubmenu={arSubmenu}
          loading={loading}
          filteredList={filteredList}
          listLoading={loading}
          listStatus={listStatus}
          userList={userList}
          primaryKey={primaryKey}
          handleFilterDate={handleFilterDate}
          createPermissionCheck={createPermissionCheck}
          updatePermissionCheck={updatePermissionCheck}
          deletePermissionCheck={deletePermissionCheck}
          loadData={loadData}
          CompanyID={CompanyID}
          setOriginalListing={setOriginalListing}
          getSearch={getSearch}
        />
      )}

      {listStatus === 'SUBMIT' && (
        // !loading &&
        <ARPostingList
          list={filteredList}
          loading={loading}
          filteredList={filteredList}
          listLoading={loading}
          listStatus={listStatus}
          postIDs={postIDs}
          setPostIDs={setPostIDs}
          userList={userList}
          handleFilterDate={handleFilterDate}
          approveRejectPermissionCheck={approveRejectPermissionCheck}
          setOriginalListing={setOriginalListing}
          arSubmenu={arSubmenu}
          loadData={loadData}
          CompanyID={CompanyID}
          getSearch={getSearch}
        />
      )}

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
