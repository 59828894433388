import { yupResolver } from '@hookform/resolvers'
import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import { Autocomplete } from '@material-ui/lab'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { AccountFooter } from 'components/Footer/AccountFooter'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  ApprovalStatus,
  GetRecurringJournalbyStatusDocument,
  useCreateRecurringJournalMutation,
  useGetAccountPeriodQuery,
  useGetJournalTypeQuery,
  useGetRecurringJournalQuery,
  useLatestOpenPeriodCheckingDateQuery,
  useUpdateRecurringJournalMutation,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtNumStr, amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as yup from 'yup'
import '../RecurringJournal.scss'
import { RecurringItems } from './RecurringJournalComponent/RecurringItemComponent'

interface RecurringJournalProps {
  CompanyID: string
  JournalTypeID: string
  RefNo: string
  Description: string
  StartYear: any
  TransactionDate: string
  StartPeriod: any
  Interval: number
  NoOfRecurrance: number
  PostAt: string
  Specify: number
  RecurringJournalItem: any
  RoutineRecurringJournalID: string
  MasterCOAID: any
  DocAmt: any
}

export const RecurringJournalForm = (props: any) => {
  const { formMode } = props

  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Draft'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
    case 'resubmit':
      form = 'Resubmit'
      mode = 'edit'
      break
  }

  let location = useLocation()
  let history = useHistory()
  const editData = location?.state as any
  const { CompanyID, RecurringJournalID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  const [openExitConf, setOpenExitConf] = useState(null)
  const [openItem, setOpenItem] = useState<boolean>(false)
  const [itemMode, setItemMode] = useState('')
  const [rjItemDataState, setRJItemData] = useState([])

  const docAmtReducer = (accumulator, currentValue) =>
    accumulator + currentValue?.DocAmt

  const totalAmount = rjItemDataState?.reduce(docAmtReducer, 0)

  const positiveAmt = rjItemDataState
    ?.filter(v => v?.DocAmt > 0)
    ?.reduce(docAmtReducer, 0)

  const negativeAmt = rjItemDataState
    ?.filter(v => v?.DocAmt < 0)
    ?.reduce(docAmtReducer, 0)

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */
  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [accPeriodYears, setAccPeriodYears] = useState(new Set())
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const {
    anchorEl,
    setAnchorEl,
    menu,
    handleClick,
    handleClose,
  } = useMenuOption()

  /* -------------------------------------------- */
  /*                   USE FORM                   */
  /* -------------------------------------------- */

  const RecurringJournalFormSchema = yup.object().shape({
    RefNo: CommonYupValidation.requireField(SystemMsgs.referenceNo()),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
    JournalTypeID: CommonYupValidation.requireField(SystemMsgs.journalType()),
    StartYear: CommonYupValidation.requireField(SystemMsgs.startYear()),
    StartPeriod: CommonYupValidation.requireField(SystemMsgs.startPeriod()),
    NoOfRecurrance: CommonYupValidation.requireField(
      SystemMsgs.noofRecurrance()
    ),
    Interval: CommonYupValidation.requireField(SystemMsgs.interval()),
    PostAt: CommonYupValidation.requireField(SystemMsgs.postAt()),
    Specify: yup.string().when('PostAt', {
      is: 'Specify',
      then: CommonYupValidation.requireField(SystemMsgs.specify()),
      otherwise: yup.string().notRequired(),
    }),
    RecurringJItem: yup.array().of(
      yup.object().shape({
        MasterCOAID: yup.string().required('Acc. Code is required'),
        DocAmt: yup
          .string()
          .notOneOf([0, '0', '0.00', '-0', '-0.00'], 'Journal Amt cannot be 0')
          .required('Amount is required'),
        CostCentreID: yup.string().required('Department is required'),
      })
    ),
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    setError,
    errors,
    watch,
    formState,
    getValues,
    clearErrors,
    formState: { isDirty },
  } = useForm<RecurringJournalProps>({
    defaultValues: {
      Description: editData ? editData?.Description : '',
      Interval: editData ? editData?.Interval : '',
      StartYear: editData ? editData?.StartYear : '',
      StartPeriod: editData ? editData?.StartPeriod : '',
      NoOfRecurrance: editData ? editData?.NoOfRecurrance : '',
      RefNo: editData ? editData?.RefNo : '',
      PostAt: editData ? editData?.PostAt : '',
      Specify: editData ? editData?.Specify : null,
      JournalTypeID: editData ? editData?.JournalTypeID : '',
      TransactionDate: editData ? editData?.TransactionDate : new Date(),
    },
    mode: 'onSubmit',
    resolver: yupResolver(RecurringJournalFormSchema),
  })

  const { fields, append, remove, insert, prepend } = useFieldArray({
    control,
    name: 'RecurringJItem',
  })

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const {
    loading: accountPeriodLoading,
    data: { getAccountPeriod } = { getAccountPeriod: [] },
  } = useGetAccountPeriodQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID, orderByAsc: 'FPeriod' },
    onCompleted: ({ getAccountPeriod }) => {
      let temp = new Set()
      getAccountPeriod?.map(x => {
        temp.add(x?.FYear)
      })
      setAccPeriodYears(temp)
    },
  })

  const {
    loading: JournalTypeLoading,
    data: { getJournalType } = { getJournalType: [] },
  } = useGetJournalTypeQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID, IsSystem: false },
  })

  const {
    loading: RecurringJournalLoading,
    data: { getRecurringJournal } = { getRecurringJournal: [] },
  } = useGetRecurringJournalQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID, RecurringJournalID: editData?.RecurringJournalID },
  })

  const {
    loading: openPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  useEffect(() => {
    if (formMode === 'edit' && editData) {
      const RJItems = editData?.RecurringJournalItem?.map(item => {
        return {
          RecurringJournalID: item?.RecurringJournalID,
          MasterCOAID: item?.MasterCOAID,
          MasterCOACode: item?.MasterCOA?.Code,
          MasterCOAName: item?.MasterCOA?.Name,
          DocAmt: item?.DocAmt,
          createdTs: item?.createdTs,
          modTs: item?.modTs,
          CostCentreID: item?.CostCentreID,
          CostCentreName: item?.CostCentre?.Name,
          CostCentreCode: item?.CostCentre?.Code,
          Remark: item?.Remark,
        }
      })
      rjItemDataState.push(...RJItems)
    }
  }, [formMode, editData])

  const docDateTimestamp = new Date(watch('TransactionDate'))
  const yearDocDate = docDateTimestamp.getFullYear()
  const monthDocDate = (docDateTimestamp.getMonth() + 1)
    .toString()
    .padStart(2, '0')
  const dayDocDate = docDateTimestamp
    .getDate()
    .toString()
    .padStart(2, '0')

  const transferDate = `${yearDocDate}-${monthDocDate}-${dayDocDate}`

  const openPeriod1 =
    transferDate >= latestOpenPeriodCheckingDate?.StartDate &&
    transferDate <= latestOpenPeriodCheckingDate?.EndDate

  const checkingYearClose1 = openPeriod1 ? false : true

  const [
    createRecurringJournal,
    {
      loading: createRecurringJournalLoading,
      called: createRecurringJournalCalled,
    },
  ] = useCreateRecurringJournalMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/general-ledger/${CompanyID}/recurring-journal`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const [
    updateRecurringJournal,
    {
      loading: updateRecurringJournalLoading,
      called: updateRecurringJournalCalled,
    },
  ] = useUpdateRecurringJournalMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setTimeout(() => {
        history.push({
          pathname: `/general-ledger/${CompanyID}/recurring-journal`,
          state: { success: true, msgMode: 'update' },
        })
      }, 500)
    },
  })

  /* -------------------------------------------- */
  /*                   ON SUBMIT                  */
  /* -------------------------------------------- */

  const onSubmit = (data, status) => {
    if (formMode === 'add') {
      createRecurringJournal({
        variables: {
          input: {
            CompanyID,
            TransactionDate: data?.TransactionDate,
            JournalTypeID: data?.JournalTypeID,
            Interval: parseFloat(amtNumStr(data?.Interval)),
            Description: data?.Description,
            StartYear: parseFloat(amtNumStr(data?.StartYear)),
            StartPeriod: parseFloat(amtNumStr(data?.StartPeriod)),
            NoOfRecurrance: parseFloat(amtNumStr(data?.NoOfRecurrance)),
            PostAt: data?.PostAt,
            Specify:
              watch('Specify') === 0
                ? null
                : parseFloat(amtNumStr(data?.Specify)),
            RefNo: data?.RefNo,
            ApprovalStatus: statusInput(status),
          },
          itemInput: rjItemDataState?.map(x => {
            return {
              MasterCOAID: x?.MasterCOAID,
              CostCentreID: x?.CostCentreID,
              Remark: x?.Remark,
              DocAmt: parseFloat(amtNumStr(x?.DocAmt)),
              Sequence: x?.Sequence,
            }
          }),
        },

        refetchQueries: [
          {
            query: GetRecurringJournalbyStatusDocument,
            variables: { CompanyID, StatusArr: ['ACTIVE'] },
          },
        ],
      })
    }
    // }
    else if (formMode !== 'add') {
      updateRecurringJournal({
        variables: {
          input: {
            RecurringJournalID,
            CompanyID,
            TransactionDate: data?.TransactionDate,
            JournalTypeID: data?.JournalTypeID,
            Interval: parseFloat(amtNumStr(data?.Interval)),
            Description: data?.Description,
            StartYear: parseFloat(amtNumStr(data?.StartYear)),
            StartPeriod: parseFloat(amtNumStr(data?.StartPeriod)),
            NoOfRecurrance: parseFloat(amtNumStr(data?.NoOfRecurrance)),
            PostAt: data?.PostAt,
            Specify:
              watch('Specify') === 0 || null
                ? null
                : parseFloat(amtNumStr(data?.Specify)),
            RefNo: data?.RefNo,
            ApprovalStatus: statusInput(status),
            Remark: data?.Remark,
          },
          itemInput: rjItemDataState?.map(y => {
            return {
              MasterCOAID: y?.MasterCOAID,
              CostCentreID: y?.CostCentreID,
              Remark: y?.Remark,
              DocAmt: parseFloat(amtNumStr(y?.DocAmt)),
              Sequence: y?.Sequence,
            }
          }),
        },
        refetchQueries: [
          {
            query: GetRecurringJournalbyStatusDocument,
            variables: { CompanyID, StatusArr: ['ACTIVE'] },
          },
        ],
      })
    }
  }

  let statusInput = mode => {
    let temp
    switch (mode) {
      case 'submit':
        temp = ApprovalStatus.Submit
        break
      case 'draft':
        temp = ApprovalStatus.Active
        break
      case 'approve':
        temp = ApprovalStatus.Completed
        break
      case 'reject':
        temp = ApprovalStatus.Rejected
        break
    }
    return temp
  }

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */

  const handleDeleteItem = index => {
    if (menu?.index > -1) {
      rjItemDataState.splice(menu?.index, 1)
    }
  }

  const createUpdateCalled = editData
    ? updateRecurringJournalCalled
    : createRecurringJournalCalled

  /* This is Array for Specify date (days) */
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate()
  }

  const generateDaysArray = () => {
    const currentDate = new Date()
    const daysInMonth = getDaysInMonth(
      currentDate.getFullYear(),
      currentDate.getMonth()
    )
    return Array.from({ length: daysInMonth - 4 }, (_, index) => index + 2)
  }

  const [daysArray] = useState(generateDaysArray())

  /* -------------------------------------------- */
  /*                    FOOTER                    */
  /* -------------------------------------------- */

  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'draft'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
  }

  const rejectFooterOption = {
    name: 'Save',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'reject'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      !!errors?.TransactionDate ||
      !!errors?.RefNo ||
      !!errors?.JournalTypeID ||
      !!errors?.Description ||
      !!errors?.NoOfRecurrance ||
      !!errors?.Interval ||
      checkingYearClose1
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.GeneralLedgerRecurringJournalUpdate,
          }),
  }

  const submitFooterOption = {
    name: 'Submit',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'submit'))()
    },
    color: 'primary',
    props: { type: 'submit' },

    //check back
    disabled:
      parseFloat(totalAmount) === 0 && rjItemDataState?.length > 1
        ? false
        : true,
  }

  let footerOptions: any[]
  if (editData?.mode === 'resubmit') {
    footerOptions = [rejectFooterOption, submitFooterOption]
  } else {
    footerOptions = [draftFooterOption, submitFooterOption]
  }
  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */

  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: editData,
      formMode: formMode,
      // for item
      itemArr: rjItemDataState,
      itemSuffixID: 'RecurringJournalItemID',
      itemTableName: 'RecurringJournalItem',
    })

  const getStartYearOptions = () => {
    const startYears = Array.from(accPeriodYears)
    const stringStartYears = startYears.map(year => String(year))
    stringStartYears.sort((a: any, b: any) => b - a)
    return stringStartYears
  }

  const getFinancialAccountPeriod = getAccountPeriod?.filter(
    x => x?.FYear === watch('StartYear') && x?.MonthEndClose === false
  )

  const handleStartYearChange = (value, newValue: any) => {
    setValue('StartYear', newValue)
  }

  return (
    <>
      {JournalTypeLoading && <Loading />}
      {accountPeriodLoading && <Loading />}
      {RecurringJournalLoading && <Loading />}
      {openPeriodCheckDateLoading && <Loading />}
      {createRecurringJournalLoading && <Loading />}
      {updateRecurringJournalLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() => {
          if (hasChanges() === true) {
            setOpenExitConf(true)
          } else {
            history.push(`/general-ledger/${CompanyID}/recurring-journal`)
          }
        }}
        smTitle={'General Ledger'}
        title={user?.companyName}
        currency={`MYR`}
        routeSegments={[
          { name: 'General Ledger' },
          { name: 'Recurring Journal' },
          { name: 'Recurring Journal', current: true },
        ]}
        rightRouteSegments={[
          {
            name:
              mode === 'add'
                ? 'New'
                : mode === 'approve-reject'
                ? 'Approve/Reject'
                : 'Edit',
            current: true,
          },
        ]}
      />

      <ContentWrapper float footer>
        <CardContents
          section={{ header: { title: 'Recurring Journal Details' } }}
        >
          <Controller
            as={
              <TextField select>
                {getJournalType
                  ?.sort((a, b) => {
                    return a.JournalType.localeCompare(b.JournalType)
                  })
                  ?.map((el, index) => (
                    <MenuItem
                      style={{ whiteSpace: 'normal' }}
                      value={el.JournalTypeID}
                      key={index}
                    >
                      <span className="text-noflow">{el.JournalType}</span>
                    </MenuItem>
                  ))}
              </TextField>
            }
            required
            className="left"
            fullWidth
            margin="dense"
            select
            name="JournalTypeID"
            label="Journal Type"
            autoComplete="off"
            control={control}
            ref={register()}
            helperText={errors?.JournalTypeID?.message}
            error={errors?.JournalTypeID ? true : false}
            defaultValue={
              mode === 'edit' || mode === 'approve-reject'
                ? editData?.JournalTypeID
                : ''
            }
            disabled={mode === 'approve-reject'}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="RefNo"
            label="Reference No."
            autoComplete="off"
            control={control}
            margin="dense"
            helperText={errors?.RefNo?.message}
            error={errors?.RefNo ? true : false}
            ref={register}
            required
            className="right"
            defaultValue={
              mode === 'edit' || mode === 'approve-reject'
                ? editData?.RefNo
                : ''
            }
            disabled={mode === 'approve-reject'}
          />

          <Controller
            as={TextField}
            name="Description"
            label="Description"
            required
            autoComplete="off"
            control={control}
            fullWidth
            helperText={errors?.Description?.message}
            error={errors?.Description ? true : false}
            ref={register}
            defaultValue={
              mode === 'edit' || mode === 'approve-reject'
                ? editData?.Description
                : ''
            }
            disabled={mode === 'approve-reject'}
          />

          {!RecurringJournalLoading && (
            <>
              <Grid
                item
                xs={6}
                style={{ marginBottom: '5px', paddingRight: '10px' }}
              >
                <Controller
                  render={({ value, onChange }) => {
                    const defVal = getRecurringJournal?.filter(
                      x =>
                        x?.RecurringJournalID === editData?.RecurringJournalID
                    )[0]
                    return (
                      <Autocomplete
                        options={getStartYearOptions()}
                        getOptionLabel={option => option}
                        fullWidth
                        onChange={(value, newValue: any) => {
                          handleStartYearChange(value, newValue)
                          setValue('StartYear', Number(newValue))
                        }}
                        renderOption={(props, option) => {
                          return <span>{props}</span>
                        }}
                        defaultValue={
                          defVal?.StartYear?.toString() ??
                          editData?.StartYear?.toString()
                        }
                        renderInput={(params: any) => {
                          return (
                            <div>
                              <TextField
                                {...params}
                                helperText={errors?.StartYear?.message}
                                error={errors?.StartYear ? true : false}
                                label="Start Financial Year"
                                style={{ width: '100%' }}
                                margin="dense"
                                type="number"
                                required
                              />
                            </div>
                          )
                        }}
                      />
                    )
                  }}
                  required
                  label="Start Financial Year"
                  name="StartYear"
                  autoComplete="off"
                  control={control}
                  multiline={true}
                  fullWidth
                  margin="dense"
                  ref={register}
                  helperText={errors?.StartYear?.message}
                  error={errors?.StartYear ? true : false}
                />
              </Grid>
            </>
          )}

          {!RecurringJournalLoading && (
            <>
              <Grid
                item
                xs={6}
                style={{ marginBottom: '5px', paddingRight: '10px' }}
              >
                <Controller
                  render={({ value, onChange }) => {
                    return (
                      <Autocomplete
                        options={getFinancialAccountPeriod || []}
                        getOptionLabel={option => String(option?.FPeriod)}
                        fullWidth
                        value={
                          getFinancialAccountPeriod.find(
                            option => option?.FPeriod === value
                          ) || null
                        }
                        onChange={(value, newValue: any) => {
                          onChange(newValue?.FPeriod)
                        }}
                        renderOption={(props, option) => {
                          return (
                            <div>
                              <div>
                                <span className="xsTitle">
                                  {props?.FPeriod}
                                </span>
                              </div>
                              <div className="desc">
                                {`${formatDate(
                                  props?.StartDate
                                )} - ${formatDate(props?.EndDate)}`}
                              </div>
                            </div>
                          )
                        }}
                        renderInput={(params: any) => {
                          return (
                            <div>
                              <TextField
                                {...params}
                                helperText={errors?.StartPeriod?.message}
                                error={errors?.StartPeriod ? true : false}
                                label="Start Period"
                                style={{ width: '100%' }}
                                margin="dense"
                                type="number"
                                required
                              />
                            </div>
                          )
                        }}
                      />
                    )
                  }}
                  label="Start Period"
                  name="StartPeriod"
                  autoComplete="off"
                  control={control}
                  multiline={true}
                  fullWidth
                  margin="dense"
                  ref={register}
                  helperText={errors?.StartPeriod?.message}
                  error={errors?.StartPeriod ? true : false}
                  required
                  className="right"
                  disabled={mode === 'approve-reject' || !watch('StartYear')}
                  defaultValue={editData?.StartPeriod ?? ''}
                />
              </Grid>
            </>
          )}

          <Controller
            ref={register}
            name="NoOfRecurrance"
            control={control}
            defaultValue={
              mode === 'edit' || mode === 'approve-reject'
                ? editData?.NoOfRecurrance
                : null
            }
            as={
              <NumberFormat
                customInput={TextField}
                id="standard-basic"
                label="No. of Recurrence"
                required
                className="left"
                autoComplete="off"
                fullWidth
                margin="dense"
                helperText={errors?.NoOfRecurrance?.message}
                error={errors?.NoOfRecurrance ? true : false}
                disabled={mode === 'approve-reject'}
                defaultValue={null}
              />
            }
          />

          <Controller
            ref={register}
            name="Interval"
            control={control}
            defaultValue={
              mode === 'edit' || mode === 'approve-reject'
                ? editData?.Interval
                : ''
            }
            as={
              <NumberFormat
                customInput={TextField}
                id="standard-basic"
                label="Interval"
                required
                autoComplete="off"
                className="right"
                fullWidth
                margin="dense"
                helperText={errors?.Interval?.message}
                error={errors?.Interval ? true : false}
                disabled={mode === 'approve-reject'}
                defaultValue={null}
              />
            }
          />

          <Controller
            as={
              <TextField select>
                {[
                  { PostAt: 'Beginning', PostAtValue: 'Beginning' },
                  { PostAt: 'Ending', PostAtValue: 'Ending' },
                  { PostAt: 'Specify', PostAtValue: 'Specify' },
                ].map((el, index) => (
                  <MenuItem
                    style={{ whiteSpace: 'normal' }}
                    value={el.PostAt}
                    key={index}
                  >
                    <span className="text-noflow">{el.PostAt}</span>
                  </MenuItem>
                ))}
              </TextField>
            }
            required
            className="left"
            fullWidth
            select
            name="PostAt"
            label="Post At"
            autoComplete="off"
            control={control}
            ref={register()}
            defaultValue={
              mode === 'edit' || mode === 'approve-reject'
                ? editData?.PostAt
                : ''
            }
            helperText={errors?.PostAt?.message}
            error={errors?.PostAt ? true : false}
            disabled={mode === 'approve-reject'}
          />

          {watch(`PostAt`) === 'Specify' ? (
            <>
              <Controller
                as={
                  <TextField select>
                    {daysArray.map((Specify, index) => (
                      <MenuItem
                        style={{ whiteSpace: 'normal' }}
                        value={Specify}
                        key={index}
                      >
                        <span className="text-noflow">{Specify}</span>
                      </MenuItem>
                    ))}
                  </TextField>
                }
                required
                className="right"
                fullWidth
                select
                name="Specify"
                label="Specify Date"
                autoComplete="off"
                control={control}
                ref={register()}
                defaultValue={
                  mode === 'edit' || mode === 'approve-reject'
                    ? editData?.Specify
                    : null
                }
                // helperText={errors?.Specify?.message}
                helperText={
                  watch('Specify') === null || watch('Specify') === Number(0)
                    ? 'Specify Date is required'
                    : null
                }
                error={
                  watch('Specify') === null || watch('Specify') === Number(0)
                    ? true
                    : false
                } // Check if Specify is 0
                disabled={mode === 'approve-reject'}
              />
            </>
          ) : null}
        </CardContents>

        <CardContents
          section={{
            header: {
              title: 'Journal Detail',
              onClickAction: () => {
                setItemMode('add')
                setOpenItem(true)
              },

              icon:
                formMode === 'add' || formMode === 'edit' ? (
                  <AddIcon
                    htmlColor="white"
                    fontSize="default"
                    style={{
                      width: '1.3rem',
                      height: '1.3rem',
                      margin: '0',
                      background: theme.palette.primary.main,
                      boxShadow: `1px 1px 4px 0px ${theme.palette.primary.main}`,
                      borderRadius: '3px',
                      color: 'rgba(224,234,254,100)',
                      marginTop: '10px',
                    }}
                  />
                ) : null,
            },
          }}
        >
          <div className="rm-padding table-wrap ">
            {rjItemDataState === undefined || rjItemDataState?.length === 0 ? (
              <EmptyList
                title="No Record Found"
                subtitle="Add New Record now."
              />
            ) : (
              rjItemDataState
                ?.sort(function(a, b) {
                  return a?.Sequence < b?.Sequence ? -1 : 1
                })
                ?.map((v, index) => {
                  return (
                    <>
                      {/* ORIGINAL */}
                      <Grid spacing={1} container className="table-content">
                        <Grid
                          item
                          xs={1}
                          style={{
                            placeSelf: 'start',
                          }}
                        >
                          <span className="xxTitle">{`${index + 1}.`}</span>
                        </Grid>

                        <Grid
                          item
                          xs={7}
                          style={{
                            placeSelf: 'start',
                            marginTop: '8px',
                          }}
                        >
                          <div>
                            <div
                              className="xxTitle text-noflow"
                              style={{ lineBreak: 'anywhere' }}
                            >
                              {v?.MasterCOACode}&nbsp;{v?.MasterCOAName}
                            </div>
                            <div className="desc text-noflow">
                              {v?.CostCentreName}
                            </div>
                            <div className="desc text-noflow">{v?.Remark}</div>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={{
                            placeSelf: 'start',
                            marginTop: '8px',
                            marginRight: 'auto',
                            textAlign: 'right',
                            flexWrap: 'wrap',
                            display: 'flex',
                            justifyContent: 'end',
                          }}
                        >
                          <div>
                            <div className="xxTitle">{amtStr(v?.DocAmt)}</div>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={1}
                          style={{
                            marginTop: '0px',
                          }}
                        >
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            aria-controls="menu-list"
                            aria-haspopup="true"
                            onClick={e =>
                              handleClick(
                                e,
                                v?.RecurringJournalItemID,
                                index,
                                v
                              )
                            }
                          >
                            <MoreVert />
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Divider
                        variant="fullWidth"
                        style={{ background: '#E4E4E4' }}
                      ></Divider>

                      <Menu
                        id="menu-list"
                        key={index}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        onClick={handleClose}
                      >
                        <MenuItem
                          onClick={() => {
                            setItemMode('edit')
                            setOpenItem(true)
                          }}
                        >
                          <span className="mdDesc">Edit</span>
                        </MenuItem>

                        <MenuItem onClick={() => handleDeleteItem(index)}>
                          <span className="mdDesc">Delete</span>
                        </MenuItem>
                      </Menu>
                    </>
                  )
                })
            )}
          </div>
        </CardContents>
      </ContentWrapper>

      {!!openItem && (
        <RecurringItems
          openItem={openItem}
          setOpenItem={setOpenItem}
          RJItemData={rjItemDataState}
          CompanyID={CompanyID}
          RecurringJournalID={RecurringJournalID}
          formMode={formMode}
          detailMode={itemMode}
          menu={menu}
          RecurringJournalItemID={
            itemMode === 'edit' ? menu?.obj?.RecurringJournalItemID : ''
          }
        />
      )}
      {footerOptions?.length > 0 ? (
        <AccountFooter options={[...footerOptions]} />
      ) : null}

      <CommonDialog
        fullWidth={true}
        open={errorDia}
        onClose={() => setErrorDia(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="smTitle flex-space"
                        style={{ color: 'red' }}
                      >
                        {'Document Numbering is empty'}
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              <span>{'Please setup Document Numbering to Submit'}</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setErrorDia(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <TotalAmountFooter
        debitCreditInfo={true}
        debitAmt={amtStr(positiveAmt) ?? '0.00'}
        creditAmt={amtStr(Math.abs(negativeAmt)) ?? '0.00'}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/general-ledger/${CompanyID}/recurring-journal`)
        }}
      />
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
