import theme from '@ifca-root/react-component/src/assets/theme'
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'
import { KeyboardArrowRight, MoreVert } from '@material-ui/icons'
import PersonIcon from '@material-ui/icons/Person'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import { DeleteDialog } from 'components/Dialog/DeleteDialog'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  ApprovalStatus,
  useDeletePaymentMutation,
  useGetDocNumTitleQuery,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate, formatTime } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const PaymentEntryList = (props: any) => {
  const {
    filteredList,
    listLoading,
    listStatus,
    userList,
    bankList,
    fetchMore,
    handleFilterDate,
  } = props
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const getSearch = localStorage?.getItem('searchFilter')
  let history = useHistory()
  const { CompanyID, BankAccountID, PaymentID }: any = useParams()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any

  //search part -----------------------------------------
  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()

  /** DELETE FUNCTION AND POPUP TRIGGER **/
  const [deletePmt, setDeletePayment] = useState(false)
  const [deleteError, setDeleteErrorPayment] = useState(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const handleClickDeleteModuleDialog = () => {
    setDeletePayment(true)
    handleClose()
  }

  const handleClickDeleteErrorPaymentDialog = () => {
    setDeleteErrorPayment(true)
    handleClose()
  }
  const {
    loading: docNumHeaderLoading,
    data: { getDocumentNumberHeader } = {
      getDocumentNumberHeader: [],
    },
  } = useGetDocNumTitleQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RefTable: 'CB_Payment',
    },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  const docNumChecker = getDocumentNumberHeader?.length === 0

  const [
    deletePayment,
    { loading: deletePaymentLoading },
  ] = useDeletePaymentMutation({
    onError: error => {
      if (error.message.includes('FK_')) {
        handleClickDeleteErrorPaymentDialog()
      }
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      fetchMore({
        variables: {
          CompanyID: CompanyID,
          BankAccountID: BankAccountID,
          StatusArr: 'ACTIVE',
        },
      })
    },
  })

  const handleDelete = data => {
    deletePayment({
      variables: {
        PaymentID: data,
      },
    })
    setDeletePayment(false)
  }
  /* DELETION ENDS*/

  /**ACL */
  return (
    <>
      {listLoading && <Loading />}
      {docNumHeaderLoading && <Loading />}
      {deletePaymentLoading && <Loading />}

      <ContentWrapper footer multiDynamicInfo style={{ marginTop: '42px' }}>
        <List className="core-list">
          {advancedFilterList(filteredList) === undefined ||
          advancedFilterList(filteredList)?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            advancedFilterList(filteredList)
              ?.filter(handleFilterDate)
              ?.sort((a, b) => {
                if (listStatus === 'COMPLETED' && !!b.DocNo) {
                  return b.DocNo.localeCompare(a.DocNo)
                }
                return 0
              })
              ?.map((el, index) => {
                return (
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <>
                            <span
                              className={`flex-space desc
                                ${
                                  el?.ApprovalStatus ===
                                  ApprovalStatus.Completed
                                    ? 'click-text'
                                    : null
                                }`}
                              style={{ fontSize: '12px', fontWeight: 'bold' }}
                              onClick={() => {
                                if (
                                  el?.ApprovalStatus ===
                                  ApprovalStatus.Completed
                                ) {
                                  history.push({
                                    pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${el?.PaymentID}/detail/preview`,
                                    state: {
                                      ...el,
                                      mode: 'detail',
                                    },
                                  })
                                }
                              }}
                            >
                              {el?.DocNo ?? el?.RefNo}
                            </span>
                          </>

                          <span
                            className="desc"
                            style={{
                              fontSize: '12px',
                              color: listStatus === 'ACTIVE' ? '#FF0000' : null,
                            }}
                          >
                            <span className="">{formatDate(el?.DocDate)}</span>
                          </span>
                        </>
                      }
                      secondary={
                        <>
                          <>
                            <span
                              className="desc"
                              style={{
                                whiteSpace: 'break-spaces',
                                lineBreak: 'auto',
                              }}
                            >
                              <Tooltip
                                disableFocusListener
                                title={
                                  <React.Fragment>
                                    <Grid container wrap="nowrap" spacing={2}>
                                      <Grid item>
                                        <Avatar
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                          }}
                                        >
                                          <PersonIcon
                                            style={{ fontSize: 'small' }}
                                          />
                                        </Avatar>
                                      </Grid>
                                      <Grid item xs zeroMinWidth>
                                        <div style={{ fontSize: '12px' }}>
                                          {userList?.find(
                                            x => x?.ID === el?.createdBy
                                          )?.name ??
                                            userList?.find(
                                              x =>
                                                x?.ID === el?.createdBy ??
                                                el?.modBy
                                            )?.name}
                                        </div>
                                        <div style={{ fontSize: '10px' }}>
                                          {formatDate(
                                            el?.modTs ?? el?.createdTs
                                          )}
                                          &nbsp;
                                          {formatTime(
                                            el?.modTs ?? el?.createdTs
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </React.Fragment>
                                }
                                arrow
                                enterTouchDelay={0}
                              >
                                <div>
                                  <IconText
                                    icon={
                                      <img
                                        src={SubmitterLog}
                                        style={{
                                          width: '12px',
                                          marginRight: '3px',
                                        }}
                                      />
                                    }
                                    font="desc"
                                  ></IconText>
                                </div>
                              </Tooltip>
                            </span>
                            <span
                              className="desc flex-space text-overflow"
                              style={{ marginBottom: '-5px' }}
                            >
                              {el?.Description}
                            </span>

                            <span className="xxTitle">
                              <span className="c-orange">
                                <span style={{ fontSize: '12px' }}>
                                  {amtStr(
                                    el?.PaymentItem?.filter(
                                      item => item?.DocAmt > 0
                                    )?.reduce((x, y) => x + y?.DocAmt, 0)
                                  )}
                                </span>
                              </span>
                            </span>
                          </>
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      {listStatus === 'ACTIVE' || listStatus === 'REJECTED' ? (
                        <>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            aria-controls="menu-list"
                            aria-haspopup="true"
                            onClick={e =>
                              handleClick(e, el?.PaymentID, index, el)
                            }
                            style={{ verticalAlign: '35px', marginTop: '40px' }}
                          >
                            <MoreVert />
                          </IconButton>
                        </>
                      ) : (
                        <IconButton edge="end" aria-label="arrow">
                          <KeyboardArrowRight
                            onClick={() =>
                              history.push({
                                pathname:
                                  // listStatus === 'ACTIVE'
                                  //   ? `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${el?.PaymentID}/edit`
                                  //   :
                                  `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${el?.PaymentID}/detail`,
                                state: el,
                              })
                            }
                          />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })
          )}
        </List>

        {listStatus === 'ACTIVE' && bankList[0]?.RecordStatus == 'ACTIVE' ? (
          <FloatButton
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.CashBookPaymentCreate,
            })}
            onClick={() => {
              if (docNumChecker) {
                setOpenSnackBar(true)
                setSnackBarMsg(SystemMsgs.errorNumberingStructure())
              } else {
                history.push({
                  pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/add`,
                })
                localStorage.removeItem('paymentItem')
                localStorage.removeItem('payment')
              }
            }}
          />
        ) : null}
      </ContentWrapper>
      {listStatus === 'ACTIVE' ? (
        <Menu
          id="menu-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.CashBookPaymentUpdate,
            })}
            onClick={() => {
              history.push({
                pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${menu?.obj?.PaymentID}/edit`,
                state: menu?.obj,
              })
            }}
          >
            <span className="">Edit</span>
          </MenuItem>
          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.CashBookPaymentDelete,
            })}
            onClick={handleClickDeleteModuleDialog}
          >
            <span className="">Delete</span>
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          id="menu-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.CashBookPaymentUpdate,
            })}
            onClick={() => {
              history.push({
                pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${menu?.obj?.PaymentID}/edit`,
                state: { ...menu?.obj, mode: 'resubmit' },
              })
            }}
          >
            <span className="">Resubmit</span>
          </MenuItem>
        </Menu>
      )}

      <DeleteDialog
        ID={menu?.ID}
        openDeleteDialog={deletePmt}
        setOpenDeleteDialog={setDeletePayment}
        title={'Payment'}
        menu={menu?.obj}
        handleDelete={handleDelete}
        passID={menu.ID}
      />

      <ErrorDialog
        errorDia={deleteError}
        setErrorDia={setDeleteErrorPayment}
        errorMsg={errMsg}
        errorHeaderMsg={'Error'}
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
