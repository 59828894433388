import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Checkbox, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { AccCodeDropdownFullWidth } from 'components/Dropdown/AccCodeDropdown'
import { AccountFooter } from 'components/Footer/AccountFooter'
import {
  useGetAccountPeriodLazyQuery,
  useGetAssignedCompanyQuery,
  useGetCompanyNameQuery,
  useGetEntityCoaLazyQuery,
  useGetMasterCoaCodeQuery,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import * as yup from 'yup'

interface TrailBalanceByEntityParamsFormProps {
  CompanyID: string
  Year: Number
  Period: Number
  GLAccount: string[]
  AccountType: string[]
}

export interface MasterCOA {
  Name: string
  MasterCOAID: string
  ParentMasterCOAID: string
  IsControl: boolean
  IsLastNode: boolean
  AccountType: string
  Code: string
  Level: number
}

export interface AccountType {
  name: string
  value: string
}

interface CompanyOption {
  Name: string
  CompanyID: string
}

export const TrailBalanceByEntityParamsForm = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const latestCompany = localStorage.getItem('latestCompany')
  const ParamsSchema = yup.object().shape({
    CompanyID: CommonYupValidation.requireField('Company is required'),
    Year: CommonYupValidation.requireField('Year is required'),
    Period: CommonYupValidation.requireField('Period is required'),
  })
  const [masterCOAData, setMasterCOAData] = useState<MasterCOA[]>([])
  const [accountTypeData, setAccountTypeData] = useState<AccountType[]>([])
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const { handleSubmit, register, errors, control, watch, setValue } = useForm<
    TrailBalanceByEntityParamsFormProps
  >({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  // ACCOUNTX API CALLS
  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    variables: { CompanyID },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (latestCompany) {
        loadAccountPeriod({
          variables: {
            CompanyID: latestCompany,
            orderByAsc: 'FYear',
          },
        })

        loadEntityCOA({
          variables: { CompanyID: latestCompany },
        })
      }
    },
  })

  const {
    loading: CompanyAssignedLoading,
    error: CompanyAssignedError,
    data: { getAssignedCompanyByUser } = { getAssignedCompanyByUser: [] },
  } = useGetAssignedCompanyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (latestCompany) {
        loadAccountPeriod({
          variables: {
            CompanyID: latestCompany,
            orderByAsc: 'FYear',
          },
        })

        loadEntityCOA({
          variables: { CompanyID: latestCompany },
        })
      }
    },
  })

  const [
    loadAccountPeriod,
    {
      loading: accountPeriodLoading,
      data: { getAccountPeriod } = { getAccountPeriod: [] },
    },
  ] = useGetAccountPeriodLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
  })

  //MasterCOA
  const {
    loading: MasterCOALoading,
    error: MasterCOAError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaCodeQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true },
  })

  const [
    loadEntityCOA,
    {
      loading: EntityCOALoading,
      data: { getEntityCOA } = { getEntityCOA: [] },
    },
  ] = useGetEntityCoaLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const masterCOAList =
        getEntityCOA?.length > 0
          ? getEntityCOA
              ?.map(coa => coa?.MasterCOA)
              ?.filter(
                coa => coa?.IsLastNode === true && coa?.IsControl === false
              )
          : getMasterCOA?.filter(
              coa => coa?.IsLastNode === true && coa?.IsControl === false
            )
      setMasterCOAData((masterCOAList as any) as MasterCOA[])

      const accountTypeList = [
        { name: 'Asset', value: 'ASSET' },
        { name: 'Liability', value: 'LIABILITY' },
        { name: 'Equity', value: 'EQUITY' },
        { name: 'Revenue', value: 'REVENUE' },
        { name: 'Expenses', value: 'EXPENSES' },
        { name: 'Statistic', value: 'STATISTIC' },
      ]
      setAccountTypeData((accountTypeList as any) as AccountType[])
    },
  })

  const getFinancialAccountPeriod = getAccountPeriod
    ?.filter(
      x => x?.FYear === watch('Year')
      // && x?.MonthEndClose === false
    )
    ?.sort((a, b) => a.FPeriod - b.FPeriod)

  const getObjectValues = (values: string[], dataType: string) => {
    switch (dataType) {
      case 'GLAccount':
        return masterCOAData.filter(x => {
          return values?.some(y => y === x.Code)
        })
        break
      case 'AccountType':
        return accountTypeData.filter(x => {
          return values?.some(y => y === x.value)
        })
        break
      default:
        return []
    }
  }

  const onSubmit = (data, status) => {
    history.push({
      pathname: `/digital-reports/general-ledger/trial-balance-entity-report/generated`,
      state: {
        CompanyID: data?.CompanyID,
        Year: Number(data.Year),
        Period: Number(data.Period),
        AccountType:
          !!watch('AccountType') && watch('AccountType')?.length
            ? watch('AccountType')?.join(',')
            : undefined,
        GLAccount:
          !!watch('GLAccount') && watch('GLAccount')?.length
            ? watch('GLAccount')?.join(',')
            : undefined,
      },
    })
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      {MasterCOALoading && <Loading />}
      {EntityCOALoading && <Loading />}
      {accountPeriodLoading && <Loading />}
      {CompanyAssignedLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/digital-reports/general-ledger`)}
        smTitle={'General Ledger'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Trial Balance By Entity', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          {!CompanyAssignedLoading && !CompanyLoading && (
            <Controller
              render={({ value, onChange }) => {
                const options: CompanyOption[] =
                  !!user?.superUser === false
                    ? getAssignedCompanyByUser
                    : getCompany
                const defVal = getCompany?.filter(
                  x => x?.CompanyID === latestCompany
                )[0]

                return (
                  <Autocomplete
                    options={options || []}
                    getOptionLabel={(option: CompanyOption) => option.Name}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      setValue('CompanyID', newValue?.CompanyID)

                      loadAccountPeriod({
                        variables: {
                          CompanyID: newValue?.CompanyID,
                          orderByAsc: 'FYear',
                        },
                      })

                      loadEntityCOA({
                        variables: { CompanyID: newValue?.CompanyID },
                      })
                    }}
                    renderOption={(props, option) => {
                      return <span>{props?.Name}</span>
                    }}
                    defaultValue={defVal}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.CompanyID?.message}
                            error={errors?.CompanyID ? true : false}
                            label="Company"
                            style={{ width: '100%' }}
                            margin="normal"
                            required
                            defaultValue={defVal}
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Company"
              name="CompanyID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              helperText={errors?.CompanyID?.message}
              error={errors?.CompanyID ? true : false}
              required
              defaultValue={latestCompany}
            />
          )}

          {!accountPeriodLoading && (
            <Controller
              render={({ value, onChange }) => {
                return (
                  <Autocomplete
                    options={
                      !!watch(`CompanyID`)
                        ? getAccountPeriod
                            ?.map(el => el?.FYear)
                            .filter((element, index) => {
                              return (
                                getAccountPeriod
                                  ?.map(el => el?.FYear)
                                  .indexOf(element) === index
                              )
                            })
                            .sort((a, b) => b - a)
                        : []
                    }
                    getOptionLabel={option => `${option}`}
                    noOptionsText="Please set up Account Period"
                    fullWidth
                    onChange={(value, newValue: any) => {
                      setValue('Year', newValue)
                    }}
                    renderOption={(props, option) => {
                      return <span>{props}</span>
                    }}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            error={errors?.Year ? true : false}
                            label="Financial Year"
                            style={{ width: '100%' }}
                            margin="normal"
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Financial Year"
              name="Year"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              error={errors?.Year ? true : false}
            />
          )}

          {/* <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={
                    getAccountPeriod
                      ?.filter(x => x?.FYear === Number(watch('Year')))
                      .sort((a, b) => b.FPeriod - a.FPeriod) || []
                  }
                  getOptionLabel={option => `${option?.FPeriod}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('Period', newValue?.FPeriod)
                  }}
                  renderOption={(props, option) => {
                    return <span>{props?.FPeriod}</span>
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          error={errors?.Period ? true : false}
                          label="Period"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Period"
            name="Period"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            error={errors?.Period ? true : false}
          /> */}

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={getFinancialAccountPeriod || []}
                  getOptionLabel={option => String(option?.FPeriod)}
                  noOptionsText="Please set up Account Period"
                  fullWidth
                  value={
                    getFinancialAccountPeriod.find(
                      option => option?.FPeriod === value
                    ) || null
                  }
                  onChange={(value, newValue: any) => {
                    onChange(newValue?.FPeriod)
                  }}
                  renderOption={(props, option) => {
                    return (
                      <span className="">
                        {`${props?.FPeriod} | ${formatDate(
                          props?.StartDate
                        )} - ${formatDate(props?.EndDate)}`}
                      </span>
                    )
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          label="Financial Period"
                          style={{ width: '100%' }}
                          margin="dense"
                          type="number"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Financial Period"
            name="Period"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            required
            error={errors?.Period ? true : false}
          />

          {!EntityCOALoading && !MasterCOALoading && (
            <Controller
              name="GLAccount"
              label="GL Account"
              fullWidth
              margin="dense"
              ref={register}
              control={control}
              render={({ onChange, onBlur, value }) => {
                return (
                  <Autocomplete
                    multiple
                    value={
                      getObjectValues(
                        watch('GLAccount'),
                        'GLAccount'
                      ) as MasterCOA[]
                    }
                    fullWidth
                    options={masterCOAData}
                    disableCloseOnSelect
                    getOptionLabel={option =>
                      `${option?.Name} | ${option?.Code}`
                    }
                    PopperComponent={AccCodeDropdownFullWidth}
                    onChange={(value, newValue: any) => {
                      onChange(newValue?.map(x => x?.Code))
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="GL Account"
                      />
                    )}
                    renderOption={(option, { selected }) => {
                      return (
                        <>
                          <Checkbox checked={selected} color="primary" />
                          <div>
                            <div>
                              <span className="xsTitle">{option.Name}</span>
                            </div>
                            <div className="desc">{option.Code}</div>
                          </div>
                        </>
                      )
                    }}
                  />
                )
              }}
            />
          )}

          <Controller
            name="AccountType"
            label="Account Type"
            fullWidth
            margin="dense"
            ref={register}
            control={control}
            render={({ onChange, onBlur, value }) => {
              return (
                <Autocomplete
                  multiple
                  value={
                    getObjectValues(
                      watch('AccountType'),
                      'AccountType'
                    ) as AccountType[]
                  }
                  fullWidth
                  options={accountTypeData}
                  disableCloseOnSelect
                  getOptionLabel={option => `${option?.name}`}
                  PopperComponent={AccCodeDropdownFullWidth}
                  onChange={(value, newValue: any) => {
                    onChange(newValue?.map(x => x?.value))
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Account Type"
                    />
                  )}
                  renderOption={(option, { selected }) => {
                    return (
                      <>
                        <Checkbox checked={selected} color="primary" />
                        <div>
                          <div>
                            <span className="xsTitle">{option.name}</span>
                          </div>
                        </div>
                      </>
                    )
                  }}
                />
              )
            }}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      </ContentWrapper>
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
      {/* FOOTER */}
    </>
  )
}
