import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import React from 'react'
import { APDebitNoteAllocation } from './APDebitNoteAllocation/APDebitNoteAllocation'
import { APDebitNoteStateForm } from './APDebitNoteForm/APDebitNoteStateForm'
import { APMain } from '../APMain'
import { APDetail } from '../APDetail/APDetail'
import { PDFPreviewV2 } from 'containers/ARModule/Hooks/PDFPreviewV2'

const debitNoteRoutes = [
  /* -------------------------------------------- */
  /*                ADVANCED FILTER               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/debit-note/filter',
    },
    component: (
      <AdvancedFilter
        mode="debit-note"
        app="account-payable"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/creditor-debit-note/filter',
    },
    component: (
      <AdvancedFilter
        mode="creditor-debit-note"
        app="account-payable"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/debit-note',
    },
    // component: (
    //   <APDebitNoteMain accountType="account-payable" docType="debitNote" />
    // ),

    component: (
      <APMain
        apSubmenu="debit-note"
        routeSegments="Debit Note"
        accountType="account-payable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/creditor-debit-note',
    },
    // component: (
    //   <APDebitNoteMain accountType="account-payable" docType="debitNote" />
    // ),

    component: (
      <APMain
        apSubmenu="creditor-debit-note"
        routeSegments="Debit Note from Creditor"
        accountType="account-payable"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/debit-note/:DocumentID/detail',
    },
    component: (
      <APDetail
        apSubmenu="debit-note"
        routeSegments="Debit Note"
        accountType="account-payable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/creditor-debit-note/:DocumentID/detail',
    },
    component: (
      <APDetail
        apSubmenu="creditor-debit-note"
        routeSegments="Debit Note from Creditor"
        accountType="account-payable"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                  PREVIEW PDF                 */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/debit-note/:DebitNoteID/preview`,
    },
    component: (
      <PDFPreviewV2 accountType="account-payable" docType="debit-note" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/creditor-debit-note/:DebitNoteID/preview`,
    },
    component: (
      <PDFPreviewV2
        accountType="account-payable"
        docType="creditor-debit-note"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                ALLOCATION                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/debit-note/:ID/allocation',
    },
    component: <APDebitNoteAllocation />,
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/debit-note/:formMode`,
    },
    component: (
      <APDebitNoteStateForm
        accountType="account-payable"
        docType="debit-note"
        routeSegments="Debit Note"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/debit-note/:ID/edit`,
    },
    component: (
      <APDebitNoteStateForm
        accountType="account-payable"
        docType="debit-note"
        routeSegments="Debit Note"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/creditor-debit-note/:formMode`,
    },
    component: (
      <APDebitNoteStateForm
        accountType="account-payable"
        docType="creditor-debit-note"
        routeSegments="Debit Note from Creditor"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/creditor-debit-note/:ID/edit`,
    },
    component: (
      <APDebitNoteStateForm
        accountType="account-payable"
        docType="creditor-debit-note"
        routeSegments="Debit Note from Creditor"
        formMode="edit"
      />
    ),
  },
]

export default debitNoteRoutes
