import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { AccountFooter } from 'components/Footer/AccountFooter'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import { FormAllocationTableContent } from 'components/Table/FormAllocationTableContent'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  GetArAllocationDocumentDocument,
  useCreateArAllocationMutation,
  useGetArAdvanceQuery,
  useGetArAllocationDocumentLazyQuery,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'

export const ARAdvanceAllocation = props => {
  useEffect(() => {
    console.log('ARAdvanceAllocation')
  }, [])
  let history = useHistory()
  const { CompanyID, AdvanceID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let location = useLocation()
  const editData = location?.state as any
  const [allocatedObj, setAllocatedObj] = useState({})
  const [val, setVal] = useState(false)
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  //----------------USE FORM---------------------//
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    control,
    errors,
    setError,
    clearErrors,
  } = useForm<any>()

  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'Allocation',
  })

  //-----------------QUERY------------------------//

  const {
    loading: ARAdvanceLoading,
    error: ARAdvanceError,
    data: { getARAdvance } = { getARAdvance: [] },
  } = useGetArAdvanceQuery({
    fetchPolicy: 'network-only',
    variables: { AdvanceID: AdvanceID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: ({ getARAdvance }) => {
      if (getARAdvance?.length > 0) {
        loadAllocationDocument({
          variables: {
            entityID: [AdvanceID],
            companyID: CompanyID,
            refTable: 'AR_Advance',
            creditorDebtorID: getARAdvance[0]?.DebtorAccountID,
          },
        })
      }
    },
  })

  const [
    loadAllocationDocument,
    {
      loading: getAllocationDocumentLoading,
      error: getAllocationDocumentError,
      data: { getAllocationDocument } = { getAllocationDocument: [] },
    },
  ] = useGetArAllocationDocumentLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: ({ getAllocationDocument }) => {
      let arr = []
      let allocDocArr = []
      if (getAllocationDocument?.length > 0) {
        getAllocationDocument?.map((alloc, index) => {
          arr.push({
            AllocationID: alloc?.AllocationID,
            CanAllocate: alloc?.CanAllocate,
            CreditOrDebit: alloc?.CreditOrDebit,
            DocDate: alloc?.DocDate,
            DocNo: alloc?.DocNo,
            Description: alloc?.Description,
            // for previous allocated amt on the same doc
            AllocationAmt: Number(
              allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0
            ),
            // balance amount + previous allocated amt on the same doc
            BalanceAmt:
              Number(alloc?.BalanceAmt) +
              Number(allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0),
            // credit or debit id
            [`${alloc?.CreditOrDebit}ID`]: alloc[`${alloc?.CreditOrDebit}ID`],
            // credit or debit ref table
            [`${alloc?.CreditOrDebit}RefTable`]: alloc[
              `${alloc?.CreditOrDebit}RefTable`
            ],
            // credit or debit doc no
            [`${alloc?.CreditOrDebit}DocNo`]: alloc[
              `${alloc?.CreditOrDebit}DocNo`
            ],
          })

          return alloc
        })
        append(arr)
      }
    },
  })

  //-------------------------MUTATION----------------------------------//
  const [
    createAllocation,
    {
      loading: CreateAllocationLoading,
      called: CreateAllocationCalled,
      error: CreateAllocationError,
    },
  ] = useCreateArAllocationMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      console.log('data', data)
      setTimeout(() => {
        history.goBack()
      }, 500)
    },
  })

  //-----------------------------ONSUBMIT-------------------------------//

  const onSubmit = (data, status) => {
    if (val == false) {
      setVal(true)
      createAllocation({
        variables: {
          input: watch('Allocation')
            ?.filter(
              alloc => Number(alloc?.AllocationAmt) !== 0 || alloc?.AllocationID
            )
            ?.map(alloc => {
              const mainCreditOrDebit =
                alloc?.CreditOrDebit === 'Debit' ? 'Credit' : 'Debit'
              return {
                ...(alloc?.AllocationID
                  ? { AllocationID: alloc?.AllocationID }
                  : null),
                AllocationAmt: Number(alloc?.AllocationAmt),
                CreditOrDebit: alloc?.CreditOrDebit,
                CompanyID: CompanyID,
                // for allocated table
                [`${alloc?.CreditOrDebit}RefTable`]: alloc?.[
                  `${alloc?.CreditOrDebit}RefTable`
                ],
                [`${alloc?.CreditOrDebit}ID`]: alloc?.[
                  `${alloc?.CreditOrDebit}ID`
                ],
                // main table
                [`${mainCreditOrDebit}RefTable`]: 'AR_Advance',
              }
            }),
          entityID: AdvanceID,
        },
        refetchQueries: [
          {
            query: GetArAllocationDocumentDocument,
            variables: {
              entityID: AdvanceID,
              companyID: CompanyID,
              refTable: 'AR_Advance',
              creditorDebtorID: getARAdvance[0]?.DebtorAccountID,
            },
          },
        ],
      })
      // }
    }
  }

  //----------------------------FOOTER---------------------------//

  const docAmt = getARAdvance[0]?.DocAmt

  const allocationTotal = watch('Allocation')?.reduce(
    (total, currentValue) => (total = total + currentValue.AllocationAmt),
    0
  )

  const minusamt = docAmt - allocationTotal

  const createUpdateCalled = CreateAllocationCalled

  const submitFooterOption = {
    name: 'Save',
    onClick: e => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'submit'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled: parseFloat(allocationTotal) > docAmt ? true : false,
  }

  const footerOptions: any[] = [submitFooterOption]

  //----------------------------USEEFFECT-------------------------------//
  useEffect(() => {
    if (editData?.Allocation) {
      let allocatedArr = editData?.Allocation
      allocatedArr?.map(allocated => {
        if (!(allocated[`${allocated?.CreditOrDebit}ID`] in allocatedObj)) {
          allocatedObj[allocated[`${allocated?.CreditOrDebit}ID`]] =
            allocated?.AllocationAmt
        } else {
          allocatedObj[allocated[`${allocated?.CreditOrDebit}ID`]] +=
            allocated?.AllocationAmt
        }
      })
    }
  }, [editData])

  return (
    <>
      {ARAdvanceLoading && <Loading />}
      {CreateAllocationLoading && <Loading />}
      {getAllocationDocumentLoading && <Loading />}

      <MainHeader
        onClick={() => history.goBack()}
        mainBtn="back"
        smTitle={'Accounts Receivable'}
        title={`${user?.companyName}`}
        routeSegments={[
          { name: 'Accounts Receivable' },
          { name: 'AP Submenu' },
          { name: 'AP Submenu' },
          { name: 'Allocation', current: true },
        ]}
        currency={user?.companyCurrencyCode}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle" style={{ color: '#ff9800' }}>
              {getARAdvance[0]?.DocNo}
            </span>
          </>
        }
        rightText={
          <span className="desc" style={{ color: 'red' }}>
            {formatDate(getARAdvance[0]?.DocDate)}
          </span>
        }
        infoLine={
          <>
            <div
              className="text-overflow"
              style={{ fontSize: '11px', width: '270px' }}
            >
              {getARAdvance[0]?.DebtorAccount?.DebtorName}
            </div>
          </>
        }
        rightInfoLine={
          <span className="desc flex-space">
            <span className="desc flex-space c-orange">
              {amtStr(getARAdvance[0]?.DocAmt)}
            </span>
          </span>
        }
      />

      <ContentWrapper multiDynamicInfo float>
        <FormAllocationTableContent
          fields={fields}
          watch={watch}
          errors={errors}
          register={register}
          control={control}
          setValue={setValue}
          clearErrors={clearErrors}
          setError={setError}
          allocatedObj={allocatedObj}
          docAmt={docAmt}
          totalAmt={allocationTotal}
          headerStyle={{
            backgroundColor: '#faf2e8',
            color: '#ff9800',
            fontWeight: '700',
          }}
          headerRightTitle={'Allocate Amt'}
          hasCheckbox={true}
        />
      </ContentWrapper>

      <TotalAmountFooter
        // docAmt={calcTotal(creditNoteItems)}
        balanceInfo={true}
        balanceAmt={minusamt}
        TotalAllocationAmt={allocationTotal}
      />

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />

      {footerOptions?.length > 0 ? (
        <AccountFooter options={[...footerOptions]} />
      ) : null}
    </>
  )
}
