import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Avatar,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import { CardExpansionHeader } from 'components/CardExpansion/CardExpansionHeader'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { RejectDialog } from 'components/Dialog/RejectDialog'
import { SubmitterDialog } from 'components/Dialog/SubmitterDialog'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import { AccountFooter } from 'components/Footer/AccountFooter'
import { TooltipAmountFooter } from 'components/Footer/TooltipAmountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  ApprovalStatus,
  GetPaymentbyStatusDocument,
  useGetBankAccountQuery,
  usePostCbPaymentMutation,
  useRejectPaymentMutation,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate, formatTime } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { PaymentDetailContent } from '../PaymentDetail/PaymentDetailContent'
import { PaymentDetailItemContent } from '../PaymentDetail/PaymentDetailItemContent'
import InfiniteScroll from 'react-infinite-scroll-component'

export const PaymentPostingList = (props: any) => {
  const {
    filteredList,
    listLoading,
    listStatus,
    postIDs,
    setPostIDs,
    userList,
    handleFilterDate,
    loadData,
    selectedField,
    sortOrder,
    fetchMore,
  } = props

  let history = useHistory()
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const { CompanyID, BankAccountID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [openDialog, setOpenDialog] = useState(false)
  const { menu: menu2, handleClick: handleClick2 }: any = useMenuOption()
  const [openExitConf, setOpenExitConf] = useState(null)
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [clickReject, setIsReject] = useState(false)
  const [rejectDialogInput, setRejectDialogInput] = useState({
    RejectionRemark: '',
  })
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const {
    loading: bankAccountLoading,
    error: bankAccountError,
    data: { getBankAccount } = { getBankAccount: [] },
  } = useGetBankAccountQuery({
    fetchPolicy: 'network-only',
    variables: { BankAccountID: BankAccountID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
  })

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */
  // handle checkbox for all employee
  const handleSelectAll = e => {
    let paymentTemp = postIDs
    if (e.target.checked) {
      filteredList.map(v => {
        paymentTemp.add(v?.PaymentID)
      })
    } else {
      paymentTemp.clear()
    }
    setPostIDs(new Set(paymentTemp))
  }

  // handle checkbox for specific entityCoa
  const handleCheckBoxChange = (e: any, paymentID, index: number) => {
    let paymentTemp = postIDs
    if (!paymentTemp.has(paymentID)) {
      paymentTemp.add(paymentID)
    } else {
      paymentTemp.delete(paymentID)
    }

    setPostIDs(new Set(paymentTemp))
  }

  const [
    postCBPayment,
    {
      loading: postPaymentLoading,
      called: postPaymentCalled,
      error: postPaymentError,
    },
  ] = usePostCbPaymentMutation({
    onError: error => {
      console.log('ERROR', error)
      setErrorDia(true)
      let errorMessage = error?.message?.substring(15)
      setErrMsg(errorMessage)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
      loadData()
    },
  })

  const [
    rejectPayment,
    {
      loading: rejectPaymentLoading,
      called: rejectPaymentCalled,
      error: rejectPaymentError,
    },
  ] = useRejectPaymentMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
      loadData()
    },
  })

  let listPostIDs: any = Array.from(postIDs || [])

  /* -------------------------------------------- */
  /*                    SUBMIT                   */
  /* -------------------------------------------- */
  const onSubmitApprove = () => {
    postCBPayment({
      variables: {
        PaymentIDs: listPostIDs,
        CompanyID: CompanyID,
        RefTable: 'CB_Payment',
      },
      refetchQueries: [
        {
          query: GetPaymentbyStatusDocument,
          variables: {
            CompanyID: CompanyID,
            BankAccountID: BankAccountID,
            StatusArr: [ApprovalStatus.Submit],
          },
        },
      ],
    })
  }

  const onSubmitReject = () => {
    rejectPayment({
      variables: {
        PaymentIDs: listPostIDs,
        input: rejectDialogInput,
      },
      refetchQueries: [
        {
          query: GetPaymentbyStatusDocument,
          variables: {
            CompanyID: CompanyID,
            BankAccountID: BankAccountID,
            StatusArr: [ApprovalStatus.Submit],
          },
        },
      ],
    })
  }

  useEffect(() => {
    if (clickReject) {
      onSubmitReject()
    }
  }, [clickReject])

  const GreyTooltip = withStyles({
    tooltip: {
      color: 'white',
      backgroundColor: '#9e9e9e',
    },
  })(Tooltip)

  let checkedData = []
  listPostIDs.map(ID => {
    filteredList.map(el => {
      if (el?.PaymentID === ID) {
        checkedData.push(...el?.PaymentItem)
      }
    })
  })

  return (
    <>
      {listLoading && <Loading />}
      {rejectPaymentLoading && <Loading />}
      {postPaymentLoading && <Loading />}
      {bankAccountLoading && <Loading />}

      <ContentWrapper
        footer
        float
        multiDynamicInfo
        style={{ marginTop: '40px' }}
      >
        {listStatus === 'SUBMIT' && filteredList?.length !== 0 ? (
          <span className="mdDesc flex-space" style={{ paddingLeft: '7px' }}>
            <Checkbox
              onChange={handleSelectAll}
              color="primary"
              value={''}
              checked={postIDs?.size === filteredList?.length}
              style={{ marginLeft: '-5px', marginTop: '-5px' }}
              disabled={handlePermDisabled({
                companyID: CompanyID,
                permEnum: AcctPermission.CashBookPaymentApproveReject,
              })}
            />
            <span style={{ marginLeft: '9px' }}>Select All</span>
          </span>
        ) : null}

        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <InfiniteScroll
              dataLength={filteredList?.length}
              next={() => {
                const currentLength = filteredList?.length
                fetchMore({
                  variables: {
                    CompanyID: CompanyID,
                    BankAccountID: BankAccountID,
                    StatusArr: [listStatus],
                    take: 30,
                    skip: currentLength,
                    searchValue:
                      getSearch?.name !== '' || getSearch?.name === undefined
                        ? undefined
                        : getSearch?.name,
                    sortField:
                      selectedField === null ? undefined : selectedField,
                    sortOrder: sortOrder === null ? undefined : sortOrder,
                  },
                })
              }}
              hasMore={true}
              className=""
              style={{ padding: '8px 6px' }}
              loader={
                listLoading && (
                  <div style={{ textAlign: 'center' }}>
                    {' '}
                    <CircularProgress />{' '}
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>-</b>
                </p>
              }
            >
              {advancedFilterList(filteredList)
                ?.filter(handleFilterDate)
                // ?.sort((a, b) => {
                //   return a.createdTs.localeCompare(b.createdTs)
                // })
                ?.map((el, index) => {
                  return (
                    <CardExpansion
                      summary={
                        <ListItem key={index}>
                          {listStatus === 'SUBMIT' ? (
                            <FormControl
                              component="fieldset"
                              style={{ width: '16px' }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={el.PaymentID}
                                    onChange={e => {
                                      handleCheckBoxChange(
                                        e,
                                        el?.PaymentID,
                                        index
                                      )
                                    }}
                                    onClick={e => e.stopPropagation()}
                                    checked={postIDs?.has(el?.PaymentID)}
                                    color="primary"
                                    style={{
                                      marginLeft: '10px',
                                      marginRight: '-5px',
                                    }}
                                    disabled={handlePermDisabled({
                                      companyID: CompanyID,
                                      permEnum:
                                        AcctPermission.CashBookPaymentApproveReject,
                                    })}
                                  />
                                }
                                label=""
                                name="check"
                              />
                            </FormControl>
                          ) : null}

                          <ListItemText
                            style={{
                              marginLeft:
                                listStatus === 'SUBMIT' ? '25px' : null,
                            }}
                            primary={
                              <>
                                <span
                                  className="xsTitle flex-space click-text"
                                  onClick={() => {
                                    history.push({
                                      pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${el?.PaymentID}/detail/preview`,
                                      state: { ...el, mode: 'detail' },
                                    })
                                  }}
                                >
                                  {el?.DocNo ?? el?.RefNo}
                                </span>
                                <span
                                  className="desc"
                                  style={{ color: '#FF0000' }}
                                >
                                  {formatDate(el?.DocDate)}
                                </span>
                              </>
                            }
                            secondary={
                              <>
                                <Grid
                                  spacing={1}
                                  container
                                  className="table-content"
                                >
                                  <Grid
                                    item
                                    xs={7}
                                    style={{
                                      position: 'absolute',
                                      contain: 'content',
                                    }}
                                  >
                                    <div
                                      className="icon-text"
                                      // style={{ marginBottom: '-12px' }}
                                      // style={{ whiteSpace: 'normal' }}
                                    >
                                      <GreyTooltip
                                        disableFocusListener
                                        title={
                                          <React.Fragment>
                                            <Grid
                                              container
                                              wrap="nowrap"
                                              spacing={2}
                                            >
                                              <Grid item>
                                                <Avatar
                                                  style={{
                                                    width: '20px',
                                                    height: '20px',
                                                  }}
                                                >
                                                  <PersonIcon
                                                    style={{
                                                      fontSize: 'small',
                                                    }}
                                                  />
                                                </Avatar>
                                              </Grid>
                                              <Grid item xs zeroMinWidth>
                                                <div
                                                  style={{ fontSize: '12px' }}
                                                >
                                                  {userList?.find(
                                                    x =>
                                                      x?.ID === el?.submittedBy
                                                  )?.name ??
                                                    userList?.find(
                                                      x =>
                                                        x?.ID ===
                                                          el?.submittedBy ??
                                                        el?.modBy
                                                    )?.name}
                                                </div>

                                                <div
                                                  style={{ fontSize: '10px' }}
                                                >
                                                  {formatDate(el?.modTs)}
                                                  &nbsp;
                                                  {formatTime(el?.modTs)}
                                                </div>
                                              </Grid>
                                            </Grid>
                                          </React.Fragment>
                                        }
                                        arrow
                                        enterTouchDelay={0}
                                      >
                                        <img
                                          src={SubmitterLog}
                                          style={{
                                            width: '12px',
                                            marginRight: '3px',
                                          }}
                                        />
                                      </GreyTooltip>
                                      <span
                                        className="desc text-overflow"
                                        style={{
                                          marginBottom: '-3px',
                                        }}
                                      >
                                        {el?.Description}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    style={{
                                      marginLeft: 'auto',
                                      flexBasis: 'auto',
                                      boxSizing: 'content-box',
                                    }}
                                  >
                                    <span className="xxTitle">
                                      <span className="c-orange">
                                        <span style={{ fontSize: '12px' }}>
                                          {amtStr(
                                            el?.PaymentItem?.filter(
                                              item => item?.DocAmt > 0
                                            )?.reduce(
                                              (x, y) => x + y?.DocAmt,
                                              0
                                            )
                                          )}
                                        </span>
                                      </span>
                                    </span>
                                  </Grid>
                                </Grid>
                              </>
                            }
                          />
                        </ListItem>
                      }
                    >
                      {
                        <>
                          <PaymentDetailContent
                            listEl={el}
                            documentListing={el?.Attachment}
                            listStatus={'SUBMIT'}
                            userList={userList}
                            mode={'expansion'}
                            bankAccount={getBankAccount[0]}
                          />
                          <CardExpansionHeader title={'Payment Detail'} />
                          <PaymentDetailItemContent
                            listItem={el?.PaymentItem}
                          />
                        </>
                      }
                    </CardExpansion>
                  )
                })}
            </InfiniteScroll>
          )}
        </List>
      </ContentWrapper>

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/cash-book/${CompanyID}/submenu/${BankAccountID}`)
        }}
      />

      <SubmitterDialog
        data={menu2?.obj}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        moduleName={'Payment'}
      />

      <RejectDialog
        data={filteredList}
        openRejectDialog={openRejectDialog}
        setOpenRejectDialog={setOpenRejectDialog}
        setRejectDialogInput={setRejectDialogInput}
        setIsReject={setIsReject}
      />

      <TooltipAmountFooter data={checkedData} module={`CB-payment-submit`} />

      {listStatus === 'SUBMIT' ? (
        <AccountFooter
          options={[
            {
              name: 'Reject',
              onClick: () => {
                setOpenRejectDialog(true)
              },
              color: 'secondary',
              props: {
                disabled:
                  postIDs.size === 0 ||
                  handlePermDisabled({
                    companyID: CompanyID,
                    permEnum: AcctPermission.CashBookPaymentApproveReject,
                  }),
              },
            },
            {
              name: 'Approve',
              onClick: () => {
                onSubmitApprove()
              },
              color: 'primary',
              props: {
                disabled:
                  postIDs.size === 0 ||
                  handlePermDisabled({
                    companyID: CompanyID,
                    permEnum: AcctPermission.CashBookPaymentApproveReject,
                  }),
              },
            },
          ]}
        />
      ) : null}

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
