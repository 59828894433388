import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { AccountFooter } from '@ifca-root/react-component/src/components/Footer/AccountFooter'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { Checkbox, TextField } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import React, { Fragment, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import * as yup from 'yup'

interface UserActivityLogParamsProps {
  StartDate: Date
  EndDate: Date
  StartLoginDate: Date
  EndLoginDate: Date
  StartLogoutDate: Date
  EndLogoutDate: Date
  UserID: string
  SuperUser: string
  ModuleName: string
}

export const UserActivityLogParamsForm = (props: any) => {
  const { submenu, smTitle } = props
  let history = useHistory()
  let location = useLocation()
  const editData = location?.state as any
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  const ParamsSchema = yup.object().shape({
    StartDate: yup.string().required('Start Date is required'),
    EndDate: yup.string().required('End Date is required'),
  })
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  const { handleSubmit, register, errors, control, watch, setValue } = useForm<
    UserActivityLogParamsProps
  >({
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  const [selectedModule, setSelectedModule] = useState(new Set())

  const handleCheckboxModuleChange = (event: any) => {
    let moduleName = selectedModule

    if (event?.target?.checked) {
      moduleName.add(event?.target?.value)
    } else {
      moduleName.delete(event?.target?.value)
    }
    setSelectedModule(new Set(moduleName))
  }

  const onSubmit = data => {
    history.push({
      pathname: `/digital-reports/${submenu}/user-activity-log-report/generated`,
      state: {
        StartDate: new Date(data?.StartDate),
        EndDate: new Date(data?.EndDate),
        StartLoginDate: data?.StartLoginDate
          ? new Date(data?.StartLoginDate)
          : undefined,
        EndLoginDate: data?.EndLoginDate
          ? new Date(data?.EndLoginDate)
          : undefined,
        StartLogoutDate: data?.StartLogoutDate
          ? new Date(data?.StartLogoutDate)
          : undefined,
        EndLogoutDate: data?.EndLogoutDate
          ? new Date(data?.EndLogoutDate)
          : undefined,
        UserID: data?.UserID ?? undefined,
        SuperUser: data?.SuperUser ?? undefined,
        ModuleName: selectedModule
          ? Array.from(selectedModule)?.toString()
          : undefined,
      },
    })
  }

  const moduleArray: string[] = [
    'ACCOUNTX',
    'GENERAL LEDGER',
    'CASH BOOK',
    'ACCOUNT RECEIVABLE',
    'ACCOUNT PAYABLE',
    'INVENTORY CONTROL',
    'GENERAL PURCHASES',
    'GENERAL SETTINGS',
    'COMPANY SETTINGS',
    'DIGITAL REPORTS',
  ]

  return (
    <>
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/digital-reports/${submenu}`)}
        smTitle={smTitle}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'User Activity Log Report', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="StartDate"
              label="Start Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('StartDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              className="left"
              defaultValue={editData?.StartDate ?? new Date()}
              helperText={errors?.StartDate?.message}
              error={errors?.StartDate ? true : false}
              showTodayButton
              fullWidth
              required
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="EndDate"
              label="End Date"
              control={control}
              onChange={(date: Date | null) => {
                setValue('EndDate', date)
              }}
              format="dd/MM/yyyy"
              value={watch('EndDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              className="right"
              defaultValue={editData?.EndDate ?? new Date()}
              helperText={errors?.EndDate?.message}
              error={errors?.EndDate ? true : false}
              showTodayButton
              fullWidth
              required
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="StartLoginDate"
              label="Start Login Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('StartLoginDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              className="left"
              defaultValue={editData?.StartLoginDate ?? null}
              helperText={errors?.StartLoginDate?.message}
              error={errors?.StartLoginDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="EndLoginDate"
              label="End Login Date"
              control={control}
              onChange={(date: Date | null) => {
                setValue('EndLoginDate', date)
              }}
              format="dd/MM/yyyy"
              value={watch('EndLoginDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              className="right"
              defaultValue={editData?.EndLoginDate ?? null}
              helperText={errors?.EndLoginDate?.message}
              error={errors?.EndLoginDate ? true : false}
              showTodayButton
              fullWidth
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                as={KeyboardDatePicker}
                name="StartLogoutDate"
                label="Start Logout Date"
                control={control}
                onChange={(date: Date | null) => {}}
                format="dd/MM/yyyy"
                value={watch('StartLogoutDate')}
                margin="normal"
                allowKeyboardControl
                ref={register}
                KeyboardButtonProps={{ 'aria-label': 'change date' }}
                className="left"
                defaultValue={editData?.StartLogoutDate ?? null}
                helperText={errors?.StartLogoutDate?.message}
                error={errors?.StartLogoutDate ? true : false}
                showTodayButton
                fullWidth
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                as={KeyboardDatePicker}
                name="EndLogoutDate"
                label="End Logout Date"
                control={control}
                onChange={(date: Date | null) => {
                  setValue('EndDate', date)
                }}
                format="dd/MM/yyyy"
                value={watch('EndDate')}
                margin="normal"
                allowKeyboardControl
                ref={register}
                KeyboardButtonProps={{ 'aria-label': 'change date' }}
                className="right"
                defaultValue={editData?.EndDate ?? null}
                helperText={errors?.EndDate?.message}
                error={errors?.EndDate ? true : false}
                showTodayButton
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </MuiPickersUtilsProvider>
          <Controller
            as={TextField}
            id="standard-basic"
            name="UserID"
            label="User ID"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.UserID?.message}
            error={errors?.UserID ? true : false}
          />
          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={['Yes', 'No']}
                  getOptionLabel={option => `${option}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('SuperUser', newValue)
                  }}
                  renderOption={option => <span>{option}</span>}
                  renderInput={(params: any) => {
                    return (
                      <TextField
                        {...params}
                        variant="outlined"
                        style={{ width: '100%' }}
                        label="Super User"
                        margin="normal"
                      />
                    )
                  }}
                />
              )
            }}
            name="SuperUser"
            label="Super User"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />
          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={moduleArray}
                  getOptionLabel={option => `${option}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selected = new Set()

                    newValue?.map(x => {
                      selected.add(x)
                    })

                    setSelectedModule(new Set(selected))
                  }}
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxModuleChange(event)
                        }}
                      />
                      {`${option}`}
                    </Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <TextField
                        {...params}
                        variant="outlined"
                        style={{ width: '100%' }}
                        label="Module"
                        margin="normal"
                      />
                    )
                  }}
                />
              )
            }}
            name="ModuleName"
            label="Module"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />
          <AccountFooter
            options={[
              {
                name: 'Submit',
                onClick: () => {
                  handleSubmit(onSubmit)()
                },
                color: 'primary',
              },
            ]}
          />
        </CardContents>
      </ContentWrapper>
    </>
  )
}
