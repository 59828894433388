import React from 'react'
import { ConsolidateSelfBilledListing } from './ConsolidateSelfBilledListing'
import { ConsolidateSelfBilledForm } from './ConsolidateSelfBilledForm'
import { ConsolidateSelfBilledDetail } from './Detail/ConsolidateSelfBilledDetail'

export const consolidateSelfBilledRoutes = [
  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/consolidate-self-billed/add',
    },
    component: <ConsolidateSelfBilledForm accountType="account-payable" />,
  },

  /* ------------------------------------------- */
  /*                  DETAIL                     */
  /* ------------------------------------------- */
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/consolidate-self-billed/:ConsolidateEInvoiceID',
    },
    component: <ConsolidateSelfBilledDetail accountType="account-payable" />,
  },

  /* -------------------------------------------- */
  /*                  LISTING                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/consolidate-self-billed',
    },
    component: <ConsolidateSelfBilledListing accountType="account-payable" />,
  },
]

export default consolidateSelfBilledRoutes
