import ApolloClient, { InMemoryCache } from 'apollo-boost'

/////////////////////////////////////////////////////////////////////////////////////////
// @subscription-node

let subscriptionNodeUrl
switch (process.env.REACT_APP_LOCAL_DB) {
  case 'local':
    subscriptionNodeUrl = 'https://subscriptionx-dev-api.ifca.io/subscriptionx'
    break
  case 'local-dev':
    subscriptionNodeUrl = 'https://subscriptionx-dev-api.ifca.io/subscriptionx'
    break
  case 'local-internal':
    subscriptionNodeUrl = 'https://accountx-internal-api.ifca.io/accountx'
    break
  case 'azure':
    subscriptionNodeUrl = 'https://subscriptionx-dev-api.ifca.io/subscriptionx'
    break
  case 'internal':
    subscriptionNodeUrl = 'https://accountx-internal-api.ifca.io/accountx'
    break
  case 'staging':
    subscriptionNodeUrl = 'https://subscriptionx-api.ifca.asia/subscriptionx'
    break
  case 'training':
    subscriptionNodeUrl = 'https://subscriptionx-api.ifca.asia/subscriptionx'
    break
  case 'demo':
    subscriptionNodeUrl = 'https://subscriptionx-api.ifca.asia/subscriptionx'
    break
  default:
    subscriptionNodeUrl = 'https://subscriptionx-api.ifca.asia/subscriptionx'
    break
}

export const SubscriptionClient = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  credentials: 'include',
  uri: subscriptionNodeUrl,
  onError: error => {
    error.graphQLErrors?.map(({ message }) => {
      console.log('message', message)
      if (message === 'session can not be established') {
        // browserHistory.push('/login', {})
      }
    })
  },
})
