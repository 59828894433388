import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ConsolidateListing } from 'containers/ARAPModule/Components/Listing/ConsolidateListing'
import {
  AcctPermission,
  EInvoiceTrxType,
  useGetSelfBilledConsolidationLazyQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import React, { useState } from 'react'

export const ConsolidateSelfBilledListing = (props: any) => {
  const documentType = [
    EInvoiceTrxType.SelfBilledInvoice,
    EInvoiceTrxType.SelfBilledCreditNote,
    EInvoiceTrxType.SelfBilledDebitNote,
    EInvoiceTrxType.SelfBilledRefundNote,
  ]
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const { filteredList, setOriginalListing } = useFuseSearch()

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const [
    fetchConsolidateSelfBilled,
    {
      loading: SelfBilledConsolidationLoading,
      data: { getSelfBilledConsolidation } = { getSelfBilledConsolidation: [] },
    },
  ] = useGetSelfBilledConsolidationLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getSelfBilledConsolidation }) => {
      if (getSelfBilledConsolidation?.length > 0) {
        setOriginalListing([...filteredList, ...getSelfBilledConsolidation])
      }
    },
  })

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */

  return (
    <>
      {SelfBilledConsolidationLoading && <Loading />}

      <ConsolidateListing
        module={'Accounts Payable'}
        title={'Self-Billed'}
        accountType={'account-payable'}
        page={'consolidate-self-billed'}
        documentType={documentType}
        fetchConsolidateData={fetchConsolidateSelfBilled}
        loading={SelfBilledConsolidationLoading}
        setOriginalListing={setOriginalListing}
        filteredList={filteredList}
        createPerm={'AcctPermission.AccPayableConsolidateSelfBilledCreate'}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
