import { AttachFile } from '@material-ui/icons'
import PrintIcon from '@material-ui/icons/Visibility'
import { AttachmentDialog } from 'components/Dialog/AttachmentDialog'
import { attachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import './APDetail.scss'

interface DetailProps {
  listEl: any
  listStatus: string
  userList: any
  documentListing?: any
  mode?: string
  apSubmenu?: string
  isSelfBilled?: boolean
}

export const APDetailContent = (props: DetailProps) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const {
    listEl,
    documentListing,
    listStatus,
    userList,
    mode,
    apSubmenu,
    isSelfBilled,
  } = props

  const [openDoc, setOpenDoc] = useState<boolean>(false)

  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu: resetVert,
  } = useMenuOption()

  let primaryKey
  let subMenuName
  switch (apSubmenu) {
    case 'advance':
      primaryKey = 'AdvanceID'
      subMenuName = 'Advance'
      break
    case 'invoice':
      primaryKey = 'InvoiceID'
      subMenuName = 'Invoice'
      break
    case 'credit-note':
      primaryKey = 'CreditNoteID'
      subMenuName = 'Credit Note'
      break
    case 'creditor-credit-note':
      primaryKey = 'CreditorCreditNoteID'
      subMenuName = 'Credit Note from Creditor'
      break
    case 'debit-note':
      primaryKey = 'DebitNoteID'
      subMenuName = 'Debit Note'
      break
    case 'creditor-debit-note':
      primaryKey = 'CreditorDebitNoteID'
      subMenuName = 'Debit Note from Creditor'
      break
    case 'payment':
      primaryKey = 'PaymentID'
      subMenuName = 'Payment'
      break

    default:
      break
  }

  let trxType =
    listEl?.einvoice_trx_type === 'SELF_BILLED_CREDIT_NOTE'
      ? 'Self-Billed Credit Note'
      : listEl?.einvoice_trx_type === 'SELF_BILLED_REFUND_NOTE'
      ? 'Self-Billed Refund Note'
      : listEl?.einvoice_trx_type === 'SELF_BILLED_DEBIT_NOTE'
      ? 'Self-Billed Debit Note'
      : 'Self-Billed Invoice'

  return (
    <>
      {mode === 'detail' && (
        <>
          <div className="content-wrap ">
            {!!listEl?.APLedger ? (
              <div className="content-wrap ">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Document Number
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {listEl?.APLedger?.DocNo}
                </div>
              </div>
            ) : apSubmenu === 'self-billed' ? (
              <div className="content-wrap full">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Transaction Type
                </div>
                <div className="xsTitle " style={{ fontWeight: 500 }}>
                  {trxType}
                </div>
              </div>
            ) : null}
            <div className="desc" style={{ fontWeight: 300 }}>
              Reference No.
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {listEl?.RefNo}
            </div>
          </div>

          <div className="content-wrap right">
            {(apSubmenu === 'advance' ||
              apSubmenu === 'credit-note' ||
              apSubmenu === 'debit-note' ||
              apSubmenu === 'payment') && (
              <div>
                <span
                  style={{ float: 'right' }}
                  onClick={() => {
                    history.push({
                      pathname: `/account-payable/${CompanyID}/${apSubmenu}/${
                        listEl?.[`${primaryKey}`]
                      }/preview`,
                      state: {
                        ...listEl,
                        mode: 'detail',
                      },
                    })
                  }}
                >
                  <PrintIcon
                    style={{
                      fontSize: 'medium',
                      padding: '3px 10px 2px 0px',
                    }}
                  />
                </span>
              </div>
            )}

            {documentListing && documentListing?.length > 0 && (
              <>
                <span
                  style={{ float: 'right', color: '#000' }}
                  onClick={() => {
                    setOpenDoc(true)
                  }}
                >
                  <AttachFile
                    className="mdDesc"
                    style={{
                      fontSize: 12,
                      color: 'orange',
                    }}
                  />

                  <span
                    className="mdDesc"
                    style={{
                      float: 'right',
                      color: 'orange',
                      textDecoration: 'underline',
                      paddingRight: '5px',
                      paddingTop: '4px',
                    }}
                  >
                    {documentListing?.length}
                  </span>
                </span>
              </>
            )}
          </div>
          <div className="content-wrap left">
            <div className="desc" style={{ fontWeight: 300 }}>
              Date
            </div>

            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {formatDate(listEl?.einvoice_self_billed_date ?? listEl?.DocDate)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ fontWeight: 300 }}>
              Transaction Date
            </div>

            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {formatDate(listEl?.TransactionDate)}
            </div>
          </div>

          {listEl?.einvoice_self_billed_date && (
            <div className="content-wrap full">
              <div className="desc" style={{ fontWeight: 300 }}>
                Self Billed Date
              </div>
              <div className="xsTitle" style={{ fontWeight: 500 }}>
                {formatDate(listEl?.DocDate)}
              </div>
            </div>
          )}

          {listEl?.DO?.length > 0 && (
            <div
              className="content-wrap full"
              // style={{ width: mode === 'expansion' ? '40%' : '50%' }}
            >
              <div className="desc" style={{ fontWeight: 300 }}>
                DO No.
              </div>
              <div className="xsTitle" style={{ fontWeight: 500 }}>
                {listEl?.DO?.map(x => x?.DocNo)}
              </div>
            </div>
          )}

          {listEl?.GRTN?.length > 0 ? (
            <div
              className="content-wrap full"
              // style={{ width: mode === 'expansion' ? '40%' : '50%' }}
            >
              <div className="desc" style={{ fontWeight: 300 }}>
                GRTN No.
              </div>
              <div className="xsTitle" style={{ fontWeight: 500 }}>
                {listEl?.GRTN?.map(x => x?.DocNo)}
              </div>
            </div>
          ) : null}

          <div className="content-wrap full">
            <div className="desc" style={{ fontWeight: 300 }}>
              Creditor Name
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {listEl?.CreditorAccount?.CompanyName}
            </div>
          </div>

          {listEl?.PaymentMethod && (
            <>
              <div className="content-wrap right">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Bank Account No
                </div>
                <div
                  className="xsTitle"
                  style={{ fontWeight: 500, whiteSpace: 'break-spaces' }}
                >
                  {`${listEl?.BankAccount?.AccountNo} (${listEl?.BankAccount?.BankProfile?.Name})`}
                </div>
              </div>

              <div className="content-wrap full">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Payment Method
                </div>
                <div
                  className="xsTitle text-noflow"
                  style={{ fontWeight: 500 }}
                >
                  {listEl?.PaymentMethod?.Name}
                </div>
              </div>

              {listEl?.PaymentMethod?.Name?.includes('Card') && (
                <div className="content-wrap right">
                  <div className="desc" style={{ fontWeight: 300 }}>
                    Card Type
                  </div>
                  <div className="xsTitle" style={{ fontWeight: 500 }}>
                    {listEl?.CreditCardType?.Name}
                  </div>
                </div>
              )}

              {listEl?.PaymentMethod?.Name === 'Cheque' && (
                <>
                  <div className="content-wrap right">
                    <div className="desc" style={{ fontWeight: 300 }}>
                      Cheque No.
                    </div>
                    <div className="xsTitle" style={{ fontWeight: 500 }}>
                      {listEl?.ChequeNo}
                    </div>
                  </div>
                  <div className="content-wrap left">
                    <div className="desc" style={{ fontWeight: 300 }}>
                      Cheque Date
                    </div>
                    <div className="xsTitle" style={{ fontWeight: 500 }}>
                      {formatDate(listEl?.ChequeDate)}
                    </div>
                  </div>

                  <div className="content-wrap right">
                    <div className="desc" style={{ fontWeight: 300 }}>
                      Cheque Expiry Date
                    </div>
                    <div className="xsTitle" style={{ fontWeight: 500 }}>
                      {formatDate(listEl?.ChequeExpiryDate)}
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          {listEl?.DocRefNo?.length > 0 && (
            <div className="content-wrap full">
              <div className="desc" style={{ fontWeight: 300 }}>
                Document Reference
              </div>
              <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
                {listEl?.DocRefNo.join(', ')}
              </div>
            </div>
          )}

          <div className="content-wrap full">
            <div className="desc" style={{ fontWeight: 300 }}>
              Description
            </div>
            <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
              {listEl?.Description ?? listEl?.Remark}
            </div>
          </div>

          {listEl?.Remark && (
            <div className="content-wrap full">
              <div className="desc" style={{ fontWeight: 300 }}>
                Remark
              </div>
              <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
                {listEl?.Remark}
              </div>
            </div>
          )}

          {listEl?.einvoice_msic_code_id && (
            <div className="content-wrap full">
              <div className="desc" style={{ fontWeight: 300 }}>
                MSIC Code
              </div>
              <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
                {`${listEl?.MSICCode?.msicCode}(${listEl?.MSICCode?.description})`}
              </div>
            </div>
          )}

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.submittedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {
                userList?.filter(user => user.ID == listEl?.submittedBy)[0]
                  ?.name
              }
            </div>
          </div>
        </>
      )}

      {listStatus === 'COMPLETED' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.approvedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {userList?.filter(user => user.ID == listEl?.approvedBy)[0]?.name}
            </div>
          </div>
        </>
      )}

      <AttachmentDialog
        title={subMenuName}
        menu={menu}
        openDoc={openDoc}
        setOpenDoc={setOpenDoc}
        attachmentDetailView={attachmentDetailView}
        DocumentListing={documentListing}
      />
    </>
  )
}
