import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar';
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar';
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable';
import { ErrorDialog } from 'components/Dialog/ErrorDialog';
import { useFuseSearch } from 'helpers/Hooks/useSearch';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  AcctPermission,
  ApprovalStatus,
  useGetGrtnHeaderLazyQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from '../../../generated/graphql';
import { GeneralGRTNEntryList } from './GeneralGRTNEntryList';
import { GeneralGRTNPostingList } from './GeneralGRTNPostingList';

export const GeneralGRTNMain = (props: any) => {
  let history = useHistory();
  const { CompanyID }: any = useParams();
  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  const approvalStatusEnum = [
    { name: 'Draft', ID: 'ACTIVE' },
    { name: 'Submitted', ID: 'SUBMIT' },
    { name: 'Rejected', ID: 'REJECTED' },
    { name: 'Approved', ID: 'COMPLETED' },
  ];

  const getSearch = JSON.parse(localStorage.getItem('searchFilter'));
  const [postIDs, setPostIDs] = useState<Set<any>>(new Set([]));
  const [labelState, setLabelState] = useState('All Warehouses');
  const [TotalLength, setTotalLength] = useState(0);
  const [listStatus, setListStatus] = useState<ApprovalStatus>(
    getSearch?.option ?? 'ACTIVE',
  );
  const [errMessage, setErrMessage] = useState(null);
  const [errDialog, setErrDialog] = useState(false);

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch();

  let condition;
  switch (listStatus) {
    case 'ACTIVE':
      condition = 'createdTs';
      break;
    case 'SUBMIT':
      condition = 'submittedTs';
      break;
    case 'COMPLETED':
      condition = 'DocNo';
      break;
    default:
      condition = 'createdTs';
      break;
  }

  useEffect(() => {
    if (!CompanyID) {
      setLabelState('All Companies');
    }
  }, [CompanyID]);

  // useEffect(() => {
  //   if (getSearch && !!originalList) {
  //     const keys = ['Description', 'RefNo', 'DocNo', 'DocDate'];
  //     const options = {
  //       shouldSort: true,
  //       threshold: 0.6,
  //       ignoreLocation: true,
  //       keys: keys,
  //     };
  //     const myFuse = new Fuse(originalList, options);
  //     const result = myFuse.search(getSearch);
  //     const val = result?.map(x => x.item);
  //     if (val.length !== 0) {
  //       handleSearch(getSearch + '', keys);
  //     }
  //   }
  // }, [getSearch, originalList]);

  // const [loadGrtn, { loading }] = useGetGrtnHeaderLazyQuery({
  //   fetchPolicy: 'network-only',
  //   variables: { CompanyID: CompanyID, orderByDesc: 'DocDate' },
  //   onCompleted: ({ getGRTNHeader }) => {
  //     if (getGRTNHeader?.length > 0) {
  //       setOriginalListing(getGRTNHeader);
  //     } else {
  //       setOriginalListing([]);
  //     }
  //   },
  // });

  const [
    loadGrtn,
    {
      loading: GeneralGRTNLoading,
      data: { getGRTNHeader, getGRTNHeaderCount } = {
        getGRTNHeader: [],
        getGRTNHeaderCount: null,
      },
    },
  ] = useGetGrtnHeaderLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
    onCompleted: ({ getGRTNHeader, getGRTNHeaderCount }) => {
      if (getGRTNHeader?.length > 0) {
        setOriginalListing([...filteredList, ...getGRTNHeader]);
      }
      if (!!getGRTNHeaderCount) {
        setTotalLength(getGRTNHeaderCount);
      }
    },
  });

  useEffect(() => {
    loadGrtn({ variables: { ApprovalStatus: listStatus } });
  }, [listStatus]);

  useEffect(() => {
    setOriginalListing([]);
    loadGrtn({
      variables: {
        CompanyID: CompanyID,
        ApprovalStatus: listStatus,
        take: 30,
        skip: 0,
        searchValue:
          getSearch?.name !== '' || getSearch?.name === undefined
            ? undefined
            : getSearch?.name,
        orderByDesc: condition,
      },
    });
  }, [listStatus]);

  const [timer, setTimer] = useState(null);
  function changeDelay(change) {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }

    setTimer(
      setTimeout(() => {
        setOriginalListing([]);
        loadGrtn({
          variables: {
            CompanyID: CompanyID,
            ApprovalStatus: listStatus,
            orderByDesc: condition,
            skip: 0,
            take: 30,
            searchValue: change,
          },
        });
      }, 1000),
    );
  }

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrMessage(error);
      setErrDialog(true);
    },
  });

  return (
    <>
      {UserLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/general-purchase/${CompanyID}`);
          localStorage.removeItem(`searchFilter`);
        }}
        smTitle={smTitle.GENERAL_PURCHASES}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Goods Return Note', current: true },
        ]}
      />

      <div className="search-filter with-dropdown-filter">
        <SearchBar
          borderRadius="4px"
          value={`${TotalLength}`}
          onChangeAction={e => {
            // handleSearch(e?.target?.value, ['DocNo', 'Description', 'RefNo']);
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: listStatus ?? 'ACTIVE',
                name: e?.target?.value,
              }),
            );
            changeDelay(e.target.value);
          }}
          isDefaultValue={!!getSearch}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', []);
            localStorage.removeItem('searchFilter');
            setOriginalListing([]);
            loadGrtn({
              variables: {
                CompanyID: CompanyID,
                ApprovalStatus: listStatus,
                orderByDesc: condition,
                skip: 0,
                take: 30,
              },
            });
          }}
        />

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={setListStatus}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(listStatus ?? 'ACTIVE')}
          searchFilter={getSearch}
        />
      </div>

      {(listStatus === 'ACTIVE' ||
        listStatus === 'COMPLETED' ||
        listStatus === 'REJECTED') && (
        // !loading &&
        <GeneralGRTNEntryList
          filteredList={filteredList}
          condition={condition}
          listLoading={GeneralGRTNLoading}
          listStatus={listStatus}
          loadRfq={loadGrtn}
          loadData={loadGrtn}
          getSearch={getSearch}
          setOriginalListing={setOriginalListing}
          userList={getUsersByAccountAndSoftware}
          createPermissionCheck={
            AcctPermission.GeneralPurchaseGoodReturnNoteCreate
          }
          updatePermissionCheck={
            AcctPermission.GeneralPurchaseGoodReturnNoteUpdate
          }
          deletePermissionCheck={
            AcctPermission.GeneralPurchaseGoodReturnNoteDelete
          }
          labelState={labelState}
        />
      )}

      {listStatus === 'SUBMIT' && (
        // !loading &&
        <GeneralGRTNPostingList
          list={filteredList}
          filteredList={filteredList}
          condition={condition}
          listLoading={GeneralGRTNLoading}
          listStatus={listStatus}
          postIDs={postIDs}
          setPostIDs={setPostIDs}
          loadGRTN={loadGrtn}
          loadData={loadGrtn}
          getSearch={getSearch}
          userList={getUsersByAccountAndSoftware}
          setOriginalListing={setOriginalListing}
          approveRejectPermissionCheck={
            AcctPermission.GeneralPurchaseGoodReturnNoteApproveReject
          }
        />
      )}

      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  );
};
